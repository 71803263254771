import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { createContext, useReducer, useEffect, useState } from "react";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return action.payload;
    case "LOGOUT":
      return null;
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const token = sessionStorage.getItem("token");

    if (token) {
      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };

      axios
        .get(`${process.env.REACT_APP_API_URL}/details`, config)
        .then((response) => {
          if (response.data) {
            dispatch({
              type: "LOGIN",
              payload: response?.data?.data,
            });
          }
        })
        .catch((error) => {
          console.error(error);

          enqueueSnackbar(
            error?.response?.data?.message || "Some error occurred",
            { variant: "error" }
          );

          if (error?.response?.data?.statusCode === 401) {
            sessionStorage.clear();

            // dispatch logout action
            dispatch({ type: "LOGOUT" });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLoading, state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
