import { createContext, useState } from "react";

export const FigAnalyticsContext = createContext();

export const FigAnalyticsContextProvider = ({ children }) => {
  const [state, setState] = useState();

  return (
    <FigAnalyticsContext.Provider value={{ state, setState }}>
      {children}
    </FigAnalyticsContext.Provider>
  );
};
