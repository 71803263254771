import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#3d3d3d",
  800: "#868686",
  900: "#161C24",
};

const PRIMARY = {
  // lighter: "#6E6E6E",
  light: "#8dc3ec",
  main: "#006BFF",
  dark: "#006BFF",
  darker: "#000000",
  contrastText: "#FFFFFF",
};

const SECONDARY = {
  lighter: "#FFFFFF",
  light: "#F5F5F5",
  main: "#FFFFFF",
  dark: "#FFFFFF",
  darker: "#FFFFFF",
  contrastText: "#000000",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff",
};

const SUCCESS = {
  lighter: "#A1F09F",
  light: "#43E040",
  main: "#04D600",
  dark: "#03A100",
  darker: "#025000",
  contrastText: GREY[800],
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800],
};

const ERROR = {
  lighter: "#FFA5A5",
  light: "#FF4B4B",
  main: "#E82C2C",
  dark: "#BF0B0B",
  darker: "#600606",
  contrastText: "#FFFFFF",
};

const palette = {
  mode: "light",
  common: { black: "#000000", white: "#FFFFFF" },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: PRIMARY.main,
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: "#fff",
    default: "#FFF",
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
