export const FooterBrandLink = [
  {
    route: '/about-us',
    name: 'About'
  },
  {
    route: '/become-a-figmaster',
    name: 'Become a FigMaster'
  },
  {
    route: '/partnership',
    name: 'Partnership'
  },
  {
    route: '/investors',
    name: 'Investors'
  },
  {
    route: '/news',
    name: 'News'
  },
  {
    route: '/safety',
    name: 'Safety'
  },
]