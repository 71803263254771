import { Box, Container, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";

const PlayerGuidelines = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Player guidelines | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Container maxWidth="lg">
        <Box sx={{ py: 5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography variant="h1" color="#000000">
              Player guidelines
            </Typography>
            <Typography variant="body" color="#000000">
              Affective as of: 1st July 2024
            </Typography>
          </Box>
          <Typography variant="h4" mt={4}>
            About these guidelines
          </Typography>
          <Box mt={2}>
            <Typography
              variant="body1"
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              We want to provide a fun and safe experience for all Cofield
              explorers across all our games, communities, social features and
              while you’re out exploring in the real world.
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              The following guidelines will help ensure you and other players
              have a great experience. Any violation of these rules can result
              in the suspension of your account. On top of these guidelines,
              other social features or channels like our official communities,
              public or private groups on Campfire, the Live Event Code of
              Conduct, and Wayfarer might have additional rules around content
              and behaviour. It is our responsibility to ensure these rules are
              clearly communicated and accessible, and yours to ensure you learn
              and abide by any additional rules in the communities that you’re a
              part of.
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              While interacting with others, be mindful that certain
              interactions that seem harmless or fun to you may be perceived
              differently by other players or bystanders.
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              If you are around other players who are violating these
              guidelines, please remove yourself from the situation and don’t
              engage. Report the situation to us through the in-app support
              options or through our web-based help centre.
            </Typography>
          </Box>
          <Typography variant="h4" mt={4}>
            1 - Treat others as you’d like to be treated
          </Typography>
          <Box mt={0.5} mx={2}>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              Unlike many other games, you may actually meet the people you play
              with in real life. It’s in everyone’s best interest to keep things
              respectful and fun for all. Respect other players and other people
              you may encounter while playing, both in-game and in the real
              world.
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              Here are a few principles of respect to keep in mind:
            </Typography>
            <ul style={{ marginTop: "10px", paddingLeft: "20px" }}>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Never defame, abuse, harass, bully, harm, stalk, or threaten
                  another player.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Be mindful of sharing personal details or identities of other
                  players without their consent, even if you know them
                  personally. This includes any information about another user’s
                  identity, including their name, phone number, email address,
                  location or physical address.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Respect the privacy of others when taking photos, videos or 3D
                  scans. If someone asks you to delete their picture or video,
                  please do so.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Do not impersonate other people or brands.
                </Typography>
              </li>
            </ul>
          </Box>
          <Typography variant="h4" mt={4}>
            2 - Adhere to the rules of the real world
          </Typography>
          <Box mt={0.5} mx={2}>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              Our games are designed to be played in public indoors and
              outdoors. As with any outdoor activity like hiking, biking, or
              playing sports in a park, we encourage everyone to exercise
              caution and play safely.
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              Here are some additional considerations as you traverse the real
              world:
            </Typography>
            <ul style={{ marginTop: "10px", paddingLeft: "20px" }}>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Remain aware of your environment and avoid going into any
                  inappropriate or dangerous areas, or any area where you are
                  not permitted to be. Do not trespass.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Be kind and respectful towards other players or bystanders
                  that you might encounter while exploring.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  When on the go, players should remain aware of hazards,
                  including other pedestrians, traffic, and obstacles.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  As with any mobile app, our games should not be used while
                  driving a vehicle.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  During live events, ensure that you’re mindful of other
                  players around you and abide by the rules of gameplay as
                  defined by Cofield.
                </Typography>
              </li>
            </ul>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              All children to play must be guided by parents or legal guardian,
              should guide and supervise their children’s usage, including where
              to play and how to use a mobile phone app safely.
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              If you ever feel that you or someone you know are in immediate
              danger, please contact local authorities immediately for
              assistance.
            </Typography>
          </Box>
          <Typography variant="h4" mt={4}>
            3 - Avoid inappropriate content and behaviour that threatens safety
          </Typography>
          <Box mt={0.5} mx={2}>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              We want our players to explore their everyday world together,
              share interesting content that they have discovered through our
              products, organize real-world gameplay events and bond over their
              shared experiences. To ensure we stay true to this mission, we may
              review the content you create within our products, flagged by
              another player or automated systems, and take action if we believe
              it’s inappropriate.
            </Typography>
            <ul style={{ marginTop: "10px", paddingLeft: "20px" }}>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Inappropriate content may include:
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Hate speech or content that promotes hatred based on players’
                  identity, for instance on the basis of their race, ethnicity,
                  religion, gender or gender identity, disability, sex or sexual
                  orientation or any other identity factor.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Content that promotes, encourages, or affiliates with
                  terrorist organizations or violent extremist groups.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Antagonistic speech or actions, including behaviour that
                  bullies, abuses, or harasses others.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Content posted to defame, abuse, harass or threaten another
                  player.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Content that displays adult nudity or sexual activity; any
                  attempt to engage in sexual or sexually suggestive
                  conversations with minors;
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Content that sexualizes minors.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Content that promotes or assists in illegal activity or
                  facilitates transaction of prohibited goods.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Content that includes graphic imagery, gore or violence.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Spam, ads, promotions, or content that is designed to mislead,
                  deceive, or otherwise harm users.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Content that violates other’s intellectual property rights
                  including copyrights or trademarks. This includes
                  impersonation or false affiliation.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Any content that promotes violations of our Terms of Service,
                  Live Event Code of Conduct, Campfire Content and Conduct
                  Policies or these guidelines themselves.
                </Typography>
              </li>
            </ul>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              The safety of our players and users is of utmost importance. If we
              are made aware that a player has committed a severe safety offense
              off-platform, we may terminate their account. These offenses
              include, but are not limited to:
            </Typography>
            <ul style={{ marginTop: "10px", paddingLeft: "20px" }}>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Conviction for sexual offenses
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Membership in a terrorist or violent extremist organization
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Mass or serial violence
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Membership in organized crime or hate groups
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Any behaviour that threatens the safety of others
                </Typography>
              </li>
            </ul>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              Each game has an intended age rating which you can find in the App
              Store. Keep this age rating in mind when sharing content and use
              discretion when interacting with others.
            </Typography>
          </Box>
          <Typography variant="h4" mt={4}>
            4 - Play fair
          </Typography>
          <Box mt={0.5} mx={2}>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              Don’t cheat. Our games are meant to be played outside on mobile
              devices and create adventures in the real world. Don’t deny
              yourself or someone else a fair gameplay experience by cheating.
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              Unfortunately, methods of cheating are limited only by cheaters’
              imaginations, and include the following:
            </Typography>
            <ul style={{ marginTop: "10px", paddingLeft: "20px" }}>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Using modified or unofficial software.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Using multiple or shared accounts to circumvent gameplay.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Using tools or techniques to alter or falsify your location
                  (“spoofing”).
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Intentionally exploiting a bug to gain reward.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Manipulating matchmaking, outcomes, rankings, or any other
                  element of competitive gameplay systems.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Abusing the refund policy.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Buying/selling/trading accounts, in-game currency, or items on
                  third-party platforms or any other activity that is in
                  violation of Cofield’s Terms of Service, These guidelines were
                  developed with consideration to human rights principles. We
                  aim to support freedom of expression, diversity, privacy,
                  transparency and due process.
                </Typography>
              </li>
            </ul>
          </Box>
          <Typography variant="h4" mt={4}>
            How we enforce the Terms of Service and these guidelines.
          </Typography>
          <Box mt={0.5} mx={2}>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              We use a mix of automated systems and user reports to identify
              content or conduct that violates the Terms of Service and/or these
              guidelines and take action against those players.
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              Cofield uses multiple technology detection systems to flag, hide,
              or block potentially dangerous or harmful content. One such system
              is used to identify known illegal images and videos of child
              sexual abuse material and report them to domestic or international
              law enforcement.
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              If you violate our Player Guidelines, we may issue a warning,
              degrade your gameplay experience, suspend your account, or
              permanently terminate your account, sometimes without warning.
              Depending on the situation, these actions may be enforced across
              one, multiple or all of our products and services we offer; and
              you may no longer be allowed to create a new Cofield account.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
};

export default PlayerGuidelines;
