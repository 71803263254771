import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { Grandstander, Play } from "../common/constants";
import { Steps } from "../common/json/features";
import DOMPurify from "dompurify";

function sanitizeAndFormatLinks(html) {
  const doc = new DOMParser().parseFromString(html, "text/html");
  const links = doc.getElementsByTagName("a");

  for (let i = 0; i < links.length; i++) {
    let href = links[i].getAttribute("href");
    if (href && !href.startsWith("http://") && !href.startsWith("https://")) {
      links[i].setAttribute("href", `https://${href}`);
    }
    links[i].setAttribute("data-external-link", "true");
  }

  return DOMPurify.sanitize(doc.body.innerHTML);
}

const FeaturesSection = ({ community, data }) => {
  const safeCommunity = Array.isArray(community) ? community : [];
  const itemsToDisplay = Array.isArray(community) ? community.slice(4) : [];
  return (
    <Container maxWidth={"xl"} sx={{ px: "0px !important" }}>
      <Box
      id="community"
      data-section
        sx={{
          mt: { xs: "50px" },
          py: { xs: "40px" },
          px: { xs: "20px", sm: "50px", md: "50px" },
          color: "#000",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: Grandstander,
              fontSize: { xs: "38px", sm: "44px", md: "48px", lg: "64px" },
              fontWeight: 600,
              letterSpacing: "-3px",
              textTransform: "uppercase",
              color: "primary.main",
            }}
          >
            Adventure Awaits, Anytime, Anywhere!
          </Typography>
          <Typography
            sx={{
              fontFamily: Play,
              fontSize: { xs: "20px", sm: "24px" },
              maxWidth: "564px",
              mt: "14px",
            }}
          >
            Unlock Figs worth ${data?.minUnlockWorth} to ${data?.maxUnlockWorth}. Your adventure, your rewards!
            Choose your tier, hit the map, and win big with Figrun. Ready to
            cash in on fun?
          </Typography>
        </Box>
        <Grid container spacing={"10px"} mt={"50px"}>
          {safeCommunity.slice(0, 4).map((data, i) => (
            <Grid key={i} item xs={12} md={i === 0 || i === 3 ? 5 : 7}>
              <Stack
                sx={{
                  backgroundColor: "#CDCDCD",
                  backgroundImage: `url(${data?.backgroundImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  p: "16px",
                  pt: "0px",
                  borderRadius: { xs: "25px", md: "30px", lg: "40px" },
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    position: "relative",
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      fontWeight: 900,
                      fontSize: { xs: "24px", md: "36px" },
                      textTransform: "uppercase",
                      fontFamily: "Grandstander, sans-serif !important",
                      mt: { xs: "100px", md: "160px", lg: "240px" },
                      color: data?.titleColor,
                    }}
                  >
                    {data?.title}
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      fontWeight: 900,
                      fontSize: { xs: "24px", md: "30px" },
                      textTransform: "uppercase",
                      fontFamily: "Grandstander, sans-serif !important",
                      // mt: { xs: "100px", md: "160px", lg: "240px" },
                    }}
                  >
                    {data?.subTitle}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", md: "20px" },
                      mt: { xs: "18px" },
                      textTransform: "uppercase",
                      lineHeight: { xs: "21.95px" },
                      fontFamily: "Grandstander, sans-serif !important",
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitizeAndFormatLinks(data?.description),
                      }}
                    />
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: { xs: "25px", md: "30px", lg: "40px" },
                      pt: { xs: "21px" },
                      pb: { xs: "13px" },
                      px: { xs: "24px" },
                      mt: "22px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "28px", md: "44px", lg: "50px" },
                        color: "primary.main",
                        fontWeight: 900,
                        textTransform: "uppercase",
                        lineHeight: {
                          xs: "27.88px",
                          md: "43.88px",
                          lg: "49.88px",
                        },
                        fontFamily: "Grandstander, sans-serif !important",
                      }}
                    >
                      {data?.name}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Grid>
          ))}

          {itemsToDisplay.map((data, i) => (
            <Grid key={i} item xs={12}>
              <Stack
                sx={{
                  backgroundColor: "#CDCDCD",
                  backgroundImage: `url(${data?.backgroundImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  p: "16px",
                  pt: "0px",
                  borderRadius: { xs: "25px", md: "30px", lg: "40px" },
                  height: "100%",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontWeight: 900,
                    fontSize: { xs: "24px", md: "36px" },
                    textTransform: "uppercase",
                    fontFamily: "Grandstander, sans-serif !important",
                    mt: { xs: "100px", md: "160px", lg: "240px" },
                  }}
                >
                  {data?.title}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    position: "relative",
                    height: "100%",
                  }}
                >
                  <List
                    sx={{
                      mt: { xs: "16px" },
                    }}
                  >
                    <ListItem disablePadding>
                      <Stack>
                        {/* <Typography
                            sx={{
                              fontSize: { xs: "16px", md: "20px" },
                              textTransform: "uppercase",
                              fontWeight: 900,
                              lineHeight: "normal",
                              fontFamily: "Grandstander, sans-serif !important",
                            }}
                          >
                            {step}. {name}:&nbsp;
                          </Typography> */}
                        <Typography
                          component={"span"}
                          sx={{
                            fontSize: { xs: "16px", md: "20px" },
                            textTransform: "uppercase",
                            fontFamily: "Grandstander, sans-serif !important",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizeAndFormatLinks(data?.description),
                            }}
                          />
                        </Typography>
                      </Stack>
                    </ListItem>
                    {/* {Steps.map(({ step }) => (
                    ))} */}
                  </List>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: { xs: "25px", md: "30px", lg: "40px" },
                      pt: { xs: "21px" },
                      pb: { xs: "13px" },
                      px: { xs: "24px" },
                      mt: "22px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "28px", md: "44px", lg: "50px" },
                        color: "primary.main",
                        fontWeight: 900,
                        textTransform: "uppercase",
                        lineHeight: {
                          xs: "27.88px",
                          md: "43.88px",
                          lg: "49.88px",
                        },
                        fontFamily: "Grandstander, sans-serif !important",
                      }}
                    >
                      {data?.name}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default FeaturesSection;
