import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "lucide-react";
import BecomeAFigmasterDialog from "./BecomeAFigmasterDialog";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";
import { navItems } from "../common/constants";

const Navbar = () => {
  const theme = useTheme();
  const isDownToSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  
  const [activeSection, setActiveSection] = useState('home')
  const sections = useRef([])
  const location = useLocation()

  const handleScroll = () => {
    const pageYOffset = window.pageYOffset
    let newActiveSection = null

    sections.current.forEach((section) => {
      const sectionOffsetTop = section.offsetTop
      const sectionHeight = section.offsetHeight

      if (
        pageYOffset >= sectionOffsetTop - 200 &&
        pageYOffset < sectionOffsetTop + sectionHeight
      ) {
        newActiveSection = section.id
      }
    })

    setActiveSection(newActiveSection)
  }

  useEffect(() => {
    sections.current = document.querySelectorAll('[data-section]')
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [location.pathname])

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const StyledLink = styled(HashLink)(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: 700,
    fontSize: "20px",
    cursor: "pointer",
  }));

  const DrawerList = (
    <Box
      sx={{
        width: 250,
        bgcolor: "#fff",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <List>
        {navItems.map((link) => (
          <ListItem key={link.title} disablePadding>
            <ListItemButton LinkComponent={HashLink} smooth to={link.link}>
              <ListItemText
                primary={link.title}
                sx={{
                  color: "primary.main",
                  "& .MuiTypography-root": {
                    fontSize: "20px",
                    fontWeight: 500,
                    fontFamily: "Grandstander, sans-serif !important",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box px={2} pb={3}>
        <Divider
          sx={{
            borderColor: "#d7d7d7",
            width: "100%",
            mb: 2,
          }}
        />
        <Button
          variant="contained"
          sx={{
            width: "100%",
            minHeight: "0",
            borderRadius: "30px",
            padding: "8px 0px",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "normal",
            fontFamily: "Grandstander, sans-serif !important",
          }}
          onClick={handleClickOpen}
        >
          BECOME A FIGMASTER
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        width: "100%",
        px: { xs: 2, sm: 3, md: 5 },
        pt: "23px",
        position: "absolute",
        // position: [
        //   "/about-us",
        //   "/admin/grains-level",
        //   "/admin/customer-support/reason",
        //   "/admin/active-figs",
        //   "/admin/draft-figs",
        //   "/admin/claimed-figs",
        // ].includes(pathname)
        //   ? "inherit"
        //   : "absolute",
        top: 0,
        zIndex: 10,
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={{ xs: "space-between" }}
        sx={{
          paddingTop: { xs: "6px", sm: "6px" },
          paddingLeft: { xs: "20px", lg: "43.24px" },
          lineHeight: "normal",
        }}
      >
        <StyledLink to={"/"}>
          <Typography
            sx={{
              fontSize: { xs: "32px", sm: "36px" },
              fontWeight: "900",
              letterSpacing: "-3px",
              fontFamily: "Grandstander, sans-serif !important",
            }}
          >
            FIGRüN
          </Typography>
        </StyledLink>
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={"40px"}
          display={{ xs: "none", lg: "flex" }}
          sx={{
            backgroundColor: "#FFFFFFB2",
            padding: "11px 31px",
            borderRadius: "30px",
          }}
        >
          {navItems.map((link) => (
            <StyledLink
              key={link.title}
              to={link.link}
              smooth
              sx={{ display: "block", width: "100%" }}
            >
              <Box className="buttons">
                <Button
                  className="btn"
                  disableRipple
                  sx={{
                    padding: "0px",
                    minHeight: "0px",
                    borderRadius: "0px",
                    ":hover": { backgroundColor: "transparent" },
                  }}
                >
                  <Typography
                    fontSize={"20px"}
                    color={"primary.main"}
                    fontWeight={700}
                    lineHeight={"normal"}
                    component={"p"}
                    data-start="good luck!"
                    data-text={link.title}
                    data-title={link.title}
                    sx={{ fontFamily: "Grandstander, sans-serif !important" }}
                  >
                    <Typography
                      component={"span"}
                      fontSize={"20px"}
                      color={"transparent"}
                      fontWeight={700}
                      lineHeight={"normal"}
                      sx={{ fontFamily: "Grandstander, sans-serif !important" }}
                      height={"18px"}
                    >
                      {link.title}
                    </Typography>
                  </Typography>
                </Button>
              </Box>
            </StyledLink>
          ))}
        </Stack>
        <Stack display={{ xs: "none", sm: "block" }}>
          <Box
            className="cta-button"
            sx={{
              backgroundColor: "primary.main",
              borderRadius: "30px",
              padding: "9px 15.5px",
              cursor: "pointer",
            }}
            onClick={handleClickOpen}
          >
            <Button
              disableRipple
              sx={{
                padding: "0px",
                minHeight: "0px",
                borderRadius: "0px",
                ":hover": { backgroundColor: "transparent" },
              }}
            >
              <Typography
                fontSize={"20px"}
                color={"#fff"}
                fontWeight={800}
                lineHeight={"normal"}
                component={"p"}
                data-start="good luck!"
                data-text="BECOME A FIGMASTER"
                data-title="BECOME A FIGMASTER"
                sx={{ fontFamily: "Grandstander, sans-serif !important" }}
              >
                <Typography
                  component={"span"}
                  fontSize={"20px"}
                  color={"transparent"}
                  fontWeight={800}
                  lineHeight={"normal"}
                  sx={{ fontFamily: "Grandstander, sans-serif !important" }}
                >
                  BECOME A FIGMASTER
                </Typography>
              </Typography>
            </Button>
          </Box>
        </Stack>
        <IconButton
          disableRipple
          sx={{
            display: { lg: "none" },
            padding: 0,
          }}
          onClick={toggleDrawer(true)}
        >
          <Menu color="#000" size={40} />
        </IconButton>
        <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
          {DrawerList}
        </Drawer>
      </Stack>
      <BecomeAFigmasterDialog open={openDialog} onClose={handleClose} />
    </Box>
  );
};

export default Navbar;
