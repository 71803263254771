import { Stack, Typography } from '@mui/material'
import React from 'react'

const AboutCard = ({subTitle, title, description, textColor}) => {
  return (
    <Stack color={textColor} sx={{
      border: '1px solid #00000024',
      boxShadow: '0px 4px 100px 0px #0000000F',
      borderRadius: {xs: '30px'},
      textTransform: 'uppercase',
      height: '100%',
      py: {xs: '36px'},
      pl: {xs: '24px'},
      pr: {xs: '11px'},
    }}>
      <Typography sx={{
        fontWeight: 900,
        fontSize: '36px',
        fontFamily: "Grandstander, sans-serif !important",
        color:"#13194B"
      }}>
        {title}
      </Typography>
      <Typography  sx={{
        fontSize: '20px',
        fontWeight: 500,
        fontFamily: "Grandstander, sans-serif !important",
        mt: '14px',
        // lineHeight: {xs: '19.95px'}
      }}>
        {subTitle}
      </Typography>
      <Typography sx={{
        fontFamily: "Grandstander, sans-serif !important",
        fontSize: '20px',
        fontWeight: 500,
        mt: '19.95px',
        lineHeight: {xs: '20px'}
      }}>
        {description}
      </Typography>
    </Stack>
  )
}

export default AboutCard
