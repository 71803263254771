import React from "react";
import { Box, Stack, Typography } from "@mui/material";

import Figfree from '../resources/icons/FigFree.png';
import Figlet from '../resources/icons/FigLet.png';
import Figlite from '../resources/icons/FigLite.png';
import Figflow from '../resources/icons/FigFlow.png';
import Figpeak from '../resources/icons/FigPeak.png';
import FigGem from '../resources/icons/GemGlow.png';

const MarqueeAnimationSection = () => {
  return (
    <Box
      bgcolor="rgba(0, 0, 0, 0.06)"
      paddingY={{ sm: "10px", md: "20px" }}
      marginY={"50px"}
      borderTop="1px solid rgba(0, 0, 0, 0.16)"
      borderBottom="1px solid rgba(0, 0, 0, 0.16)"
      position="relative"
      sx={
        {
          // overflowX: 'clip',
        }
      }
    >
      <Box className="marqueeLandingPage">
        <Stack
          direction="row"
          alignItems="center"
          gap="26px"
          sx={{ textWrap: "nowrap" }}
        >
          {Array.from({ length: 5 }).map((item, index) => (
            <React.Fragment key={index}>
              <img src={Figfree} alt="gem" className="marquee-gems"/>
              <Typography
                color="primary"
                variant="h1"
                mt={1.3}
                textTransform="uppercase"
                fontSize={{ xs: "30px", sm: "44px", md: "70px" }}
                fontWeight={700}
                lineHeight={"69.83px"}
                sx={{ fontFamily: "Grandstander, sans-serif !important" }}
              >
                Unlock adventure, No cost
              </Typography>
              <img src={Figlet} alt="gem" className="marquee-gems"/>
              <Typography
                color="primary"
                variant="h1"
                mt={1.3}
                textTransform="uppercase"
                fontSize={{ xs: "30px", sm: "44px", md: "70px" }}
                fontWeight={700}
                lineHeight={"69.83px"}
                sx={{ fontFamily: "Grandstander, sans-serif !important" }}
              >
                Start Your Journey
              </Typography>
              <img src={Figlite} alt="gem" className="marquee-gems"/>
              <Typography
                color="primary"
                variant="h1"
                mt={1.3}
                textTransform="uppercase"
                fontSize={{ xs: "30px", sm: "44px", md: "70px" }}
                fontWeight={700}
                lineHeight={"69.83px"}
                sx={{ fontFamily: "Grandstander, sans-serif !important" }}
              >
                Elevate your quest
              </Typography>
              <img src={Figflow} alt="gem" className="marquee-gems"/>
              <Typography
                color="primary"
                variant="h1"
                mt={1.3}
                textTransform="uppercase"
                fontSize={{ xs: "30px", sm: "44px", md: "70px" }}
                fontWeight={700}
                lineHeight={"69.83px"}
                sx={{ fontFamily: "Grandstander, sans-serif !important" }}
              >
                Unleash the Explorer
              </Typography>
              <img src={Figpeak} alt="gem" className="marquee-gems"/>
              <Typography
                color="primary"
                variant="h1"
                mt={1.3}
                textTransform="uppercase"
                fontSize={{ xs: "30px", sm: "44px", md: "70px" }}
                fontWeight={700}
                lineHeight={"69.83px"}
                sx={{ fontFamily: "Grandstander, sans-serif !important" }}
              >
                Reach the Summit
              </Typography>
              <img src={FigGem} alt="gem" className="marquee-gems-glow"/>
              <Typography
                color="primary"
                variant="h1"
                mt={1.3}
                textTransform="uppercase"
                fontSize={{ xs: "30px", sm: "44px", md: "70px" }}
                fontWeight={700}
                lineHeight={"69.83px"}
                sx={{ fontFamily: "Grandstander, sans-serif !important" }}
              >
                Collect. Use. Win.
              </Typography>
            </React.Fragment>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default MarqueeAnimationSection;
