import PropTypes from "prop-types";
import { useMemo } from "react";
// @mui
import { colors, CssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
//
import palette from "./palette";
import shadows from "./shadows";
import typography from "./typography";
import GlobalStyles from "./globalStyles";
import customShadows from "./customShadows";
import zIndex from "@mui/material/styles/zIndex";

// ----------------------------------------------------------------------

const ThemeProvider = (props) => {
  const { children } = props;

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = {
    MuiOutlinedInput: {
      styleOverrides: {
        root: { color: theme.palette.common.black, borderRadius: 4 },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: 2,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: { fontSize: 15, color: theme.palette.grey[700] },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: 16,
          fontWeight: 500,
          borderRadius: "27px",
          minHeight: 56,
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.main,
        },
        dotActive: {
          width: 40,
          borderRadius: 4,
          opacity: 1,
        },
        dot: {
          height: 6,
          width: 6,
          margin: "0 4px",
          opacity: 0.6,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiMobileStepper-root": {
            flexGrow: "unset",
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: theme.palette.common.white,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiListItemButton-root": {
            padding: "8px 16px",
            minHeight: "unset",
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: 16,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "12px 24px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: { borderColor: theme.palette.common.white, opacity: 0.5 },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "100%",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          zIndex: 1,
          fontWeight: 600,
          color: "grey",
          "&.Mui-selected": {
            color: "white",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #ffffff inset",
            WebkitTextFillColor: "#000000",
          },
        },
      },
    },
  };

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default ThemeProvider;
