export const About = [
  {
    number: "500k",
    title: "Instant wins!",
    subTitle: "",
    description:
      "Instant payouts, real cash rewards! Unlock Figs, collect Gems, and see your winnings transferred instantly into your account.",
    textColor: "#006BFF",
  },
  {
    number: "25k",
    title: "Real-time ",
    subTitle: "Real-time Reward Visibility",
    description:
      "Figrun sets itself apart by displaying the exact amount of GEMS AKA money available, updating live as rewards are claimed. YOU JUST NEED TO GET UP, WALK AND FIND THEM",
    textColor: "#E55EFF",
  },
  {
    number: "76k",
    title: "STAY CONNECTED",
    subTitle: "Connect on the Go",
    description:
      "See your location and where your friends are on the map, making it a socially engaging experience that enhances your sense of community.",
    textColor: "#00C800",
  },
  {
    number: "876k",
    title: "TELL US WHERE?",
    description:
      "LEAD WITH A SIGNAL Users can influence the game by LIKING LOCATIONS OF DEPLOYMENT, WE RESPOND BY adding a layer of personalisation and ownership to the gameplay.",
    textColor: "#FF3D00",
  },
];
