import { Box, Container, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";

const EventCodeOfConduct = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Event code of conduct | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Container maxWidth="lg">
        <Box sx={{ py: 5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography variant="h1" color="#000000">
              Live Event Code of Conduct
            </Typography>
            <Typography variant="body" color="#000000">
              Affective as of: 1st July 2024
            </Typography>
          </Box>
          <Typography variant="h4" mt={4}>
            Purpose
          </Typography>
          <Box mt={2}>
            <Typography
              variant="body1"
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              Cofield’s mission is to draw people outdoors, inspire exploration,
              encourage exercise, and create social and community connection.
              Our live events are a core way we fulfill this mission, and we
              want to make sure Players are able to have a safe and enjoyable
              experience. To make this happen, we ask that all Players, vendors,
              media, volunteers, and employees abide by this Code of Conduct in
              addition to our Terms of Service at Cofield events. Players
              attending a Meetup organized are also expected to uphold our Code
              of Conduct throughout the event.
            </Typography>
          </Box>
          <Typography variant="h4" mt={4}>
            Expected Behaviour
          </Typography>
          <Box mt={0.5} mx={2}>
            <ul style={{ marginTop: "10px", paddingLeft: "20px" }}>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Be considerate, respectful, and cooperative.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Follow the rules and standards set by event staff.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Be respectful of the event space or location.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Be mindful of your surroundings. If you or someone else you
                  notice is in danger, alert event staff.
                </Typography>
              </li>
            </ul>
          </Box>
          <Typography variant="h4" mt={4}>
            Prohibited Behaviour
          </Typography>
          <Box mt={0.5} mx={2}>
            <ul style={{ marginTop: "10px", paddingLeft: "20px" }}>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Acts or threats of violence.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Dangerous activities that could put you or someone else at
                  risk of physical harm.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Possession of weapons, including firearms and accessories,
                  explosives, knives, or any other tool that may cause serious
                  bodily harm or death.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Use or possession of illicit drugs or other illegal
                  substances.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Lewd or sexual acts.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Nonconsensual or unwanted physical contact or sexual
                  attention, including sexualized jokes, touching, and sexual
                  advances.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Behaviour that shames, degrades, or harasses someone based off
                  of their race, gender, religion, caste, sexual orientation,
                  gender identity, race, ethnicity, nationality, immigration
                  status, medical condition, veteran status, or disability.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Encouraging, committing, or soliciting any unlawful act,
                  including theft or defacing property.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Refusing to follow the rules and standards set by event staff.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Harassing, mistreating or instigating conflict with Cofield
                  employees, Players, or other attendees.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Advocating for, or encouraging, any of the above behaviour
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "justify", color: "#000000" }}
                >
                  Impersonating or misrepresenting yourself as a Cofield
                  employee, media event staff, or event sponsor.
                </Typography>
              </li>
            </ul>
          </Box>
          <Typography variant="h4" mt={4}>
            Consequences
          </Typography>
          <Box mt={0.5} mx={2}>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              Cofield staff may, at their discretion, take any action they deem
              appropriate for any Player or attendee engaging in prohibited
              behaviour, including asking an attendee to stop that behaviour,
              reporting the Player or attendee to security, or expelling them
              from the event without a refund. Players or attendees who violate
              this Code of Conduct may be prohibited from participating in
              future live events. If the violation takes place at a Meetup, the
              Player or attendee may be barred from participating in future
              Meetups. All determinations of unacceptable behaviour are at the
              discretion of Cofield staff, and their decision(s) is(are) final.
            </Typography>
            <Typography
              variant="body1"
              mt={1}
              sx={{ textAlign: "justify", color: "#000000" }}
            >
              Should the violator have any Cofield accounts, Cofield reserves
              the right to suspend or ban any account or remove any related
              content at any time for any violations of the Code of Conduct at a
              live event.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
};

export default EventCodeOfConduct;
