import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container } from "@mui/material";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet-async";
import StaticPageDes from "../components/StaticPageDes";
import Footer from "../components/Footer";
import axios from "axios";

const BecomeAFigMaster = () => {
  const [loading, setLoading] = useState(true);
  const [aboutUsMedia, sesAboutUsMedia] = useState();

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/web/figmaster/media`)
      .then((response) => {
        setLoading(false);
        sesAboutUsMedia(response?.data?.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Become a FigMaster | Figrun</title>
      </Helmet>
      <Box
        sx={{
          overflowX: "hidden",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box
          sx={{
            height: "80vh",
            minHeight: "700px",
            overflow: "scroll",
            padding: 2.5,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            id="hero"
            sx={{
              height: "100%",
              borderRadius: { xs: "15px", sm: "30px", md: "50px" },
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              position: "relative",
              backgroundColor: "#ece7e4",
            }}
          >
            {aboutUsMedia && aboutUsMedia.media ? (
              aboutUsMedia.mediaType === "video" ? (
                <video
                  src={aboutUsMedia.media}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "inherit",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    backgroundImage: `url(${aboutUsMedia.media})`,
                    height: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: "inherit",
                  }}
                />
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <Navbar />
          </Box>
        </Box>
        <Container maxWidth="lg">
          <StaticPageDes
            title={"BECOME A FIGMASTER"}
            des1={
              "Unlock the potential of your influence and creativity by becoming a FigMaster! FigMasters are not just players; they are the architects of thrilling experiences. With access to exclusive tools, FigMasters can deploy their own Figs at physical or virtual locations of their choosing. Challenge your followers to hunt down your Figs and unlock incredible rewards while you earn a share of the profits. Becoming a FigMaster is an ideal opportunity for influencers, content creators, and community leaders to monetize their influence in an innovative way."
            }
            des2={
              "You’ll create custom treasure hunts, engage your audience in new ways, and turn your platform into a hub of adventure and excitement. Plus, as a FigMaster, you’ll have priority access to exclusive rewards, events, and community recognition."
            }
          />
        </Container>
        <Footer />
      </Box>
    </>
  );
};

export default BecomeAFigMaster;
