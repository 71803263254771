import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container } from "@mui/material";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet-async";
import StaticPageDes from "../components/StaticPageDes";
import Footer from "../components/Footer";
import axios from "axios";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);
  const [aboutUsMedia, sesAboutUsMedia] = useState();

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/web/about-us/media`)
      .then((response) => {
        setLoading(false);
        sesAboutUsMedia(response?.data?.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>About | Figrun</title>
      </Helmet>
      <Box
        sx={{
          overflowX: "hidden",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box
          sx={{
            height: "80vh",
            minHeight: "700px",
            overflow: "scroll",
            padding: 2.5,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            id="hero"
            sx={{
              height: "100%",
              borderRadius: { xs: "15px", sm: "30px", md: "50px" },
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              position: "relative",
              backgroundColor: "#ece7e4",
            }}
          >
            {aboutUsMedia && aboutUsMedia.media ? (
              aboutUsMedia.mediaType === "video" ? (
                <video
                  src={aboutUsMedia.media}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "inherit",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    backgroundImage: `url(${aboutUsMedia.media})`,
                    height: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: "inherit",
                  }}
                />
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <Navbar />
          </Box>
        </Box>
        <Container maxWidth="lg">
          <StaticPageDes
            title={"ABOUT FIGRUN"}
            des1={
              "Figrun is a revolutionary discovery app designed to turn the world around you into a playground of adventure, exploration, and rewards. By blending elements of real-world exploration with virtual gameplay, Figrun lets users discover hidden locations called “Figs” on a dynamic, interactive map. Unlocking Figs rewards users with Gems, which can be converted into real-world money or spent on in-game perks."
            }
            des2={
              "Our mission is to break the boundaries between the physical and digital worlds, creating a truly immersive experience where every step leads to discovery, adventure, and real rewards. Whether you’re walking through your city or venturing into new territories, Figrun turns ordinary locations into extraordinary opportunities for excitement and monetary gain."
            }
          />
        </Container>
        <Footer />
      </Box>
    </>
  );
};

export default AboutUs;
