export const ROLE_SUPER_ADMIN = "superAdmin";

export const Play = "Play, sans-serif !important";
export const Grandstander = "Grandstander, sans-serif !important";

export const providerType = {
  google: "Google",
  apple: "Apple",
  facebook: "Facebook",
};

export const MEDIA_TYPE = {
  IMAGE: "image",
  VIDEO: "video",
  GIF: "gif",
};

export const navItems = [
  { title: 'FIGPASS', link: '/#figpass' },
  { title: 'GAMEPLAY', link: '/#gameplay' },
  { title: 'FEATURES', link: '/#features' },
  { title: 'COMMUNITY', link: '/#community' },
]

export const invalidCharsForNumberInput = ["-", "+", "e", "E", "."];

export const figUnlockTypes = ["physical", "virtual"];

export const figTypes = ["figfree", "figlet", "figlite", "figflow", "figpeak"];

export const twoDecimals = /^\d{1,6}(\.\d{0,2})?$/;

export const sixDecimals = /^\d{1,6}(\.\d{0,6})?$/;
