import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";
import { Play } from "../common/constants";
import FigpassCard from "./FigpassCard";
import GlowGemsIcon from "../resources/icons/Shape_blue-donute-u.svg";

const FigpassSection = ({ figPlan, gem }) => {
  return (
    <Container maxWidth={"xl"} sx={{ px: "0px !important" }}>
      <Box
        id="figpass"
        data-section
        sx={{
          px: { xs: "20px", sm: "50px", md: "53px" },
          py: { xs: "50px" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "32px", sm: "50px" },
            fontWeight: 600,
            color: "primary.main",
            lineHeight: { xs: "32px", sm: "49.88px" },
            textTransform: "uppercase",
            fontFamily: "Grandstander, sans-serif !important",
          }}
        >
          Choose Your Path to Adventure with Figpasses
        </Typography>
        <Typography
          sx={{
            fontFamily: Play,
            fontSize: { xs: "16px", sm: "24px" },
            // lineHeight: '34.71px',
            mt: "10px",
            color: "#000",
          }}
        >
          Embark on your adventure with our range of Figpasses, each tailored to
          different levels of exploration. From the casual discovery with
          Figfree to the elite challenges of Figpeak, select your pass and
          unlock unique opportunities to discover, collect, and win. Choose your
          adventure, earn rewards, and transform your explorations into
          real-world gains!
        </Typography>

        <Grid container mt={{ xs: "60px" }} spacing={"10px"}>
          {figPlan ? (
            <>
              {figPlan.map(
                ({
                  id,
                  image,
                  name,
                  price,
                  description,
                  gems,
                  grainPerFig,
                  grainsLevelUp,
                }) => (
                  <Grid key={id} item xs={12} md={6} lg={4}>
                    <FigpassCard
                      icon={image}
                      name={name}
                      price={price}
                      description={description}
                      gems={gems}
                      grainPerFig={grainPerFig}
                      grainsLevelUp={grainsLevelUp}
                    />
                  </Grid>
                )
              )}
              <Grid item xs={12} md={6} lg={4}>
                <FigpassCard
                  icon={GlowGemsIcon}
                  name={"GEMS"}
                  price={null}
                  description={gem?.details}
                  gems={null}
                  grainPerFig={null}
                  grainsLevelUp={null}
                />
              </Grid>
            </>
          ) : (
            Array.from({ length: 6 }).map((_, i) => (
              <Grid key={i} item xs={12} md={6} lg={4}>
                <Skeleton
                  variant="rectangular"
                  sx={{
                    borderRadius: { xs: "20px", sm: "30px" },
                    width: "100%",
                    height: { xs: "300px", sm: "500px" },
                  }}
                />
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default FigpassSection;
