import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container } from "@mui/material";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet-async";
import StaticPageDes from "../components/StaticPageDes";
import Footer from "../components/Footer";
import axios from "axios";

const News = () => {
  const [loading, setLoading] = useState(true);
  const [aboutUsMedia, sesAboutUsMedia] = useState();

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/web/news/media`)
      .then((response) => {
        setLoading(false);
        sesAboutUsMedia(response?.data?.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>News | Figrun</title>
      </Helmet>
      <Box
        sx={{
          overflowX: "hidden",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box
          sx={{
            height: "80vh",
            minHeight: "700px",
            overflow: "scroll",
            padding: 2.5,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            id="hero"
            sx={{
              height: "100%",
              borderRadius: { xs: "15px", sm: "30px", md: "50px" },
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              position: "relative",
              backgroundColor: "#ece7e4",
            }}
          >
            {aboutUsMedia && aboutUsMedia.media ? (
              aboutUsMedia.mediaType === "video" ? (
                <video
                  src={aboutUsMedia.media}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "inherit",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    backgroundImage: `url(${aboutUsMedia.media})`,
                    height: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: "inherit",
                  }}
                />
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <Navbar />
          </Box>
        </Box>
        <Container maxWidth="lg">
          <StaticPageDes
            title={"NEWS"}
            des1={
              "Stay in the loop with all things Figrun! Our news section is your go-to source for the latest developments, including feature updates, partnerships, community highlights, and exciting announcements. Whether it’s a new event launch, a feature that’s going to change the way you play, or stories of users winning big and sharing their adventures, this is the place to keep informed. "
            }
            des2={
              "We’re constantly evolving to deliver the best experiences to our community, and you won’t want to miss what’s next. From beta launches to global expansion updates, our news feed is your gateway to all the action."
            }
          />
        </Container>
        <Footer />
      </Box>
    </>
  );
};

export default News;
