import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  InputLabel,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import { Grandstander, Play } from "../common/constants";
import { ReactComponent as AddOne } from "../resources/icons/outline_add-one.svg";
import { ReactComponent as Close } from "../resources/icons/cross.svg";
import { ReactComponent as Trash } from "../resources/icons/trash.svg";
import { FieldArray, Formik, Form, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

const BecomeAFigmasterDialog = ({ onClose, open }) => {
  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First name is required!"),
    lastName: yup.string().required("Last name is required!"),
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email is required!"),
    socialLinks: yup
      .array()
      .of(yup.string().url("Invalid URL").required("URL is required"))
      .required("At least one social link is required")
      .min(1, "At least one social link is required"),
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    socialLinks: [""],
  };

  const StyledInput = styled(Field)(({ theme, error }) => ({
    fontFamily: Play,
    fontSize: "14px",
    width: "100%",
    marginTop: "8px",
    padding: "18px 16px",
    backgroundColor: "#9C9C9C1A !important",
    borderRadius: "12px",
    border: `1px solid ${error ? "#FF0000" : "#9C9C9C1F"}`,
  }));

  const StyledForm = styled(Form)(({ theme }) => ({
    marginTop: "32px",
  }));

  const handleFigmasterClose = () => {
    onClose();
  };

  const handleSubmit = async (values, formik) => {
    const transformedValues = {
      ...values,
      socialLinks: values.socialLinks.join(","),
    };
    const config = {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/web/influencer-request`,
        transformedValues,
        config
      )
      .then((response) => {
        enqueueSnackbar(response?.data?.message, {
          variant: "success",
        });
        console.log(response?.data?.message);
        formik.resetForm();
        handleFigmasterClose();
      })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.message || "Something went wrong",
          { variant: "error" }
        );
      });
  };

  return (
    <Dialog
      onClose={handleFigmasterClose}
      open={open}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "550px",
          borderRadius: "30px",
          marginX: { xs: "20px", sm: "32px" },
          "&::-webkit-scrollbar": { display: "none" },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "end",
          pb: 0,
          px: { xs: "20px", sm: "24px" },
          position: { xs: "static", sm: "sticky" },
          top: 0,
          left: 0,
        }}
      >
        <Button
          disableRipple
          sx={{
            minHeight: "0px",
            minWidth: "0px",
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={handleFigmasterClose}
        >
          <Close />
        </Button>
      </DialogTitle>
      <Box
        sx={{
          px: { xs: "20px", sm: "50px" },
          pb: "30px",
        }}
      >
        <Box textAlign={"center"} px={"5px"}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "600",
              fontFamily: Grandstander,
              textTransform: "uppercase",
              letterSpacing: "-1px",
            }}
          >
            Become a figmaster, Lead the hunt!
          </Typography>
          <Typography
            sx={{
              color: "#9c9c9c",
              fontFamily: Play,
              fontWeight: 400,
              fontSize: "13px",
              mt: 1,
            }}
          >
            Join the ranks of Figrun's elite by deploying Figs in the real
            world. Shape the game, engage your followers, and earn rewards.
            Start your journey now!
          </Typography>
        </Box>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched }) => (
            <StyledForm>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <Box width={"100%"}>
                  <InputLabel
                    sx={{
                      fontSize: "14px",
                      fontFamily: Play,
                    }}
                  >
                    First name
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "14px",
                        fontFamily: Play,
                        color: "#FF0000",
                      }}
                    >
                      *
                    </Typography>
                  </InputLabel>
                  <StyledInput
                    placeholder="Enter first name"
                    name="firstName"
                    id="firstName"
                    error={errors.firstName && touched.firstName}
                    helperText={
                      touched.firstName && errors.firstName && errors.firstName
                    }
                    sx={{
                      paddingY: { xs: "16px", sm: "18px" },
                    }}
                  />
                  {errors.firstName && touched.firstName && (
                    <Typography
                      sx={{ color: "#FF0000", fontSize: "12px", mt: "4px" }}
                    >
                      {errors.firstName}
                    </Typography>
                  )}
                </Box>
                <Box width={"100%"}>
                  <InputLabel
                    sx={{
                      fontSize: "14px",
                      fontFamily: Play,
                    }}
                  >
                    Last name
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "14px",
                        fontFamily: Play,
                        color: "#FF0000",
                      }}
                    >
                      *
                    </Typography>
                  </InputLabel>
                  <StyledInput
                    placeholder="Enter last name"
                    name="lastName"
                    id="lastName"
                    error={errors.lastName && touched.lastName}
                    helperText={
                      touched.lastName && errors.lastName && errors.lastName
                    }
                    sx={{
                      paddingY: { xs: "16px", sm: "18px" },
                    }}
                  />
                  {errors.lastName && touched.lastName && (
                    <Typography
                      sx={{ color: "#FF0000", fontSize: "12px", mt: "4px" }}
                    >
                      {errors.lastName}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box mt={"18px"}>
                <InputLabel
                  sx={{
                    fontSize: "14px",
                    fontFamily: Play,
                  }}
                >
                  Email Address
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "14px",
                      fontFamily: Play,
                      color: "#FF0000",
                    }}
                  >
                    *
                  </Typography>
                </InputLabel>
                <StyledInput
                  placeholder="Enter your email"
                  name="email"
                  id="email"
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email && errors.email}
                  sx={{
                    paddingY: { xs: "16px", sm: "18px" },
                  }}
                />
                {errors.email && touched.email && (
                  <Typography
                    sx={{ color: "#FF0000", fontSize: "12px", mt: "4px" }}
                  >
                    {errors.email}
                  </Typography>
                )}
              </Box>
              <Box mt={"18px"}>
                <InputLabel
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Play",
                  }}
                >
                  Social links
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Play",
                      color: "#FF0000",
                    }}
                  >
                    *
                  </Typography>
                </InputLabel>
                <FieldArray
                  name="socialLinks"
                  render={({ push, remove }) => (
                    <Box>
                      {values.socialLinks &&
                        values.socialLinks.length > 0 &&
                        values.socialLinks.map((link, index) => (
                          <Box key={index}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                mt: 1,
                              }}
                            >
                              <StyledInput
                                placeholder="Paste url here"
                                name={`socialLinks[${index}]`}
                                error={
                                  errors.socialLinks &&
                                  errors.socialLinks[index]
                                }
                                helperText={
                                  touched.socialLinks &&
                                  touched.socialLinks[index] &&
                                  errors.socialLinks &&
                                  errors.socialLinks[index]
                                }
                                sx={{
                                  marginTop: 0,
                                  paddingY: { xs: "16px", sm: "18px" },
                                }}
                              />
                              {values.socialLinks.length > 1 && (
                                <Button
                                  type="button"
                                  disableRipple
                                  sx={{
                                    minHeight: "0px",
                                    minWidth: "0px",
                                    backgroundColor: "rgba(255, 0, 0, 0.1)",
                                    borderRadius: "9.69px",
                                    flexShrink: 0,
                                    p: "11.31px",
                                    border:
                                      "0.81px solid rgba(255, 0, 0, 0.12)",
                                    ":hover": {
                                      backgroundColor: "rgba(255, 0, 0, 0.2)",
                                    },
                                  }}
                                  onClick={() => remove(index)}
                                >
                                  <Trash />
                                </Button>
                              )}
                            </Box>
                            {errors.socialLinks &&
                              errors.socialLinks[index] && (
                                <Typography
                                  sx={{
                                    color: "#FF0000",
                                    fontSize: "12px",
                                    mt: "4px",
                                  }}
                                >
                                  {errors.socialLinks[index]}
                                </Typography>
                              )}
                          </Box>
                        ))}
                      <Button
                        type="button"
                        disableRipple
                        endIcon={<AddOne />}
                        sx={{
                          minHeight: 0,
                          p: 0,
                          mt: 1,
                          fontFamily: "Play",
                          fontWeight: 700,
                          ":hover": {
                            backgroundColor: "transparent",
                          },
                          "& .MuiButton-icon": {
                            ml: "6px",
                          },
                        }}
                        onClick={() => {
                          if (values.socialLinks.length < 5) {
                            push("");
                          } else {
                            enqueueSnackbar("Maximum 5 social links allowed", {
                              variant: "warning",
                            });
                          }
                        }}
                      >
                        ADD MORE
                      </Button>
                    </Box>
                  )}
                />
              </Box>

              <Button
                type="submit"
                variant="contained"
                sx={{
                  minHeight: 0,
                  display: "block",
                  width: "100%",
                  mt: "30px",
                  py: "15px",
                  fontFamily: Grandstander,
                  borderRadius: "24px",
                  fontWeight: 800,
                  fontSize: "24px",
                }}
              >
                SUBMIT
              </Button>
            </StyledForm>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default BecomeAFigmasterDialog;
