export const Testimonials = [
  {
    firstName: 'RACHEL',
    lastName: 'GREEN',
    position: 'Figrun Hunter',
    review: "Ready to transform your daily routes into routes of discovery? Figrun is your playfield. 🌍",
    image: 'https://mui.com/static/images/avatar/3.jpg',
  },
  {
    firstName: 'RACHEL',
    lastName: 'GREEN',
    position: 'Figrun Hunter',
    review: "Ready to transform your daily routes into routes of discovery? Figrun is your playfield. 🌍",
    image: 'https://mui.com/static/images/avatar/2.jpg',
  },
  {
    firstName: 'RACHEL',
    lastName: 'GREEN',
    position: 'Figrun Hunter',
    review: "Ready to transform your daily routes into routes of discovery? Figrun is your playfield. 🌍",
    image: 'https://mui.com/static/images/avatar/1.jpg',
  },
]