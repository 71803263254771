import React from "react";
import { Box, Container } from "@mui/material";
import PhoneFrame from "../resources/images/frame.png";

const StickyPhoneSection = ({ simulatorMedia, gamePlayMedia }) => {
  return (
    <Box id="features" data-section bgcolor={"#13194B"}>
      <Container maxWidth={"xl"} sx={{ px: "0px !important" }}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
          }}
        >
          <Box
            sx={{
              maxWidth: "100%",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                pt: "50px",
                pb: "60px",
              }}
            >
              <Box
                component={"ul"}
                sx={{
                  display: "flex",
                  listStyle: "none",
                  paddingInlineStart: "0",
                }}
              >
                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "20px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  >
                    {gamePlayMedia?.[0]?.mediaType === "image" ? (
                      <img
                        className="background-media"
                        loading="lazy"
                        src={gamePlayMedia?.[0]?.media}
                        alt="Iphone 14 pro"
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    ) : (
                      <video
                        className="background-media"
                        autoPlay
                        muted
                        loop
                        src={gamePlayMedia?.[0]?.media}
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "15px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  >
                    {gamePlayMedia?.[1]?.mediaType === "image" ? (
                      <img
                        className="background-media"
                        loading="lazy"
                        src={gamePlayMedia?.[1]?.media}
                        alt="Iphone 14 pro"
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    ) : (
                      <video
                        className="background-media"
                        autoPlay
                        muted
                        loop
                        src={gamePlayMedia?.[1]?.media}
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    )}
                  </Box>
                </Box>

                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "20px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  ></Box>
                </Box>

                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "20px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  >
                    {gamePlayMedia?.[2]?.mediaType === "image" ? (
                      <img
                        className="background-media"
                        loading="lazy"
                        src={gamePlayMedia?.[2]?.media}
                        alt="Iphone 14 pro"
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    ) : (
                      <video
                        className="background-media"
                        autoPlay
                        muted
                        loop
                        src={gamePlayMedia?.[2]?.media}
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "20px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  >
                    {gamePlayMedia?.[3]?.mediaType === "image" ? (
                      <img
                        className="background-media"
                        loading="lazy"
                        src={gamePlayMedia?.[3]?.media}
                        alt="Iphone 14 pro"
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    ) : (
                      <video
                        className="background-media"
                        autoPlay
                        muted
                        loop
                        src={gamePlayMedia?.[3]?.media}
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                component={"ul"}
                sx={{
                  display: "flex",
                  listStyle: "none",
                  paddingInlineStart: "0",
                }}
              >
                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "20px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  >
                    {gamePlayMedia?.[4]?.mediaType === "image" ? (
                      <img
                        className="background-media"
                        loading="lazy"
                        src={gamePlayMedia?.[4]?.media}
                        alt="Iphone 14 pro"
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    ) : (
                      <video
                        className="background-media"
                        autoPlay
                        muted
                        loop
                        src={gamePlayMedia?.[4]?.media}
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "15px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  >
                    {gamePlayMedia?.[5]?.mediaType === "image" ? (
                      <img
                        className="background-media"
                        loading="lazy"
                        src={gamePlayMedia?.[5]?.media}
                        alt="Iphone 14 pro"
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    ) : (
                      <video
                        className="background-media"
                        autoPlay
                        muted
                        loop
                        src={gamePlayMedia?.[5]?.media}
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    )}
                  </Box>
                </Box>

                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "20px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  ></Box>
                </Box>

                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "20px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  >
                    {gamePlayMedia?.[6]?.mediaType === "image" ? (
                      <img
                        className="background-media"
                        loading="lazy"
                        src={gamePlayMedia?.[6]?.media}
                        alt="Iphone 14 pro"
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    ) : (
                      <video
                        className="background-media"
                        autoPlay
                        muted
                        loop
                        src={gamePlayMedia?.[6]?.media}
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "20px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  >
                    {gamePlayMedia?.[7]?.mediaType === "image" ? (
                      <img
                        className="background-media"
                        loading="lazy"
                        src={gamePlayMedia?.[7]?.media}
                        alt="Iphone 14 pro"
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    ) : (
                      <video
                        className="background-media"
                        autoPlay
                        muted
                        loop
                        src={gamePlayMedia?.[7]?.media}
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                component={"ul"}
                sx={{
                  display: "flex",
                  listStyle: "none",
                  paddingInlineStart: "0",
                }}
              >
                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "20px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  >
                    {gamePlayMedia?.[8]?.mediaType === "image" ? (
                      <img
                        className="background-media"
                        loading="lazy"
                        src={gamePlayMedia?.[8]?.media}
                        alt="Iphone 14 pro"
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    ) : (
                      <video
                        className="background-media"
                        autoPlay
                        muted
                        loop
                        src={gamePlayMedia?.[8]?.media}
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "15px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  >
                    {gamePlayMedia?.[9]?.mediaType === "image" ? (
                      <img
                        className="background-media"
                        loading="lazy"
                        src={gamePlayMedia?.[9]?.media}
                        alt="Iphone 14 pro"
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    ) : (
                      <video
                        className="background-media"
                        autoPlay
                        muted
                        loop
                        src={gamePlayMedia?.[9]?.media}
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    )}
                  </Box>
                </Box>

                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "20px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  ></Box>
                </Box>

                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "20px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  >
                    {gamePlayMedia?.[10]?.mediaType === "image" ? (
                      <img
                        className="background-media"
                        loading="lazy"
                        src={gamePlayMedia?.[10]?.media}
                        alt="Iphone 14 pro"
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    ) : (
                      <video
                        className="background-media"
                        autoPlay
                        muted
                        loop
                        src={gamePlayMedia?.[10]?.media}
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  component={"li"}
                  sx={{
                    borderRadius: { xs: "20px", sm: "25px", md: "30px" },
                    margin: { xs: "0px 10px 20px", md: "0px 15px 30px" },
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "inherit",
                      width: { xs: "150px", sm: "200px", md: "268px" },
                      height: { xs: "324.63px", sm: "432.84px", md: "580px" },
                      position: "relative",
                    }}
                  >
                    {gamePlayMedia?.[11]?.mediaType === "image" ? (
                      <img
                        className="background-media"
                        loading="lazy"
                        src={gamePlayMedia?.[11]?.media}
                        alt="Iphone 14 pro"
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    ) : (
                      <video
                        className="background-media"
                        autoPlay
                        muted
                        loop
                        src={gamePlayMedia?.[11]?.media}
                        style={{
                          height: "100%",
                          width: "100%",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              position: "absolute",
              left: 0,
              top: "50px",
              width: "100%",
              height: "calc(100% - 50px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                margin: "auto auto 0",
                width: "100%",
                // height: '740px',
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                overflow: "hidden",
                position: "sticky",
                bottom: 0,
              }}
            >
              <img
                className="sticky-image"
                src={PhoneFrame}
                alt="Holding Iphone 14"
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                  color: "transparent",
                  zIndex: 100,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  zIndex: 2,
                  width: { xs: "150px", sm: "210px", md: "275px", lg: "275px" },
                  height: {
                    xs: "325px",
                    sm: "445px",
                    md: "580px",
                    lg: "590px",
                  },
                  top: { xs: "8px", sm: "10px", md: "24px", lg: "15px" },
                  borderRadius: { xs: "20px", sm: "40px", lg: "36px" },
                  overflow: "hidden",
                  //           width: 275px;
                  // height: 580px;
                  // top: 24px;
                  // border-radius: 40px;
                }}
              >
                {simulatorMedia && simulatorMedia.mediaType === "video" ? (
                  <video
                    src={simulatorMedia.media}
                    autoPlay
                    muted
                    loop
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                ) : simulatorMedia ? (
                  <img
                    src={simulatorMedia.media}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default StickyPhoneSection;
