export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ xs, sm, md, lg }) {
  return {
    "@media (min-width:0px)": {
      fontSize: `${xs}px`,
    },
    "@media (min-width:600px)": {
      fontSize: `${sm}px`,
    },
    "@media (min-width:900px)": {
      fontSize: `${md}px`,
    },
    "@media (min-width:1200px)": {
      fontSize: `${lg}px`,
    },
  };
}

// ----------------------------------------------------------------------

const FONT_PRIMARY = "Poppins, sans-serif"; // Google Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: "40px",
    ...responsiveFontSizes({ xs: 28, sm: 32, md: 36, lg: 40 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: "32px",
    ...responsiveFontSizes({ xs: 24, sm: 26, md: 28, lg: 32 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: "24px",
    ...responsiveFontSizes({ xs: 21, sm: 22, md: 23, lg: 24 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: "20px",
    ...responsiveFontSizes({ xs: 17, sm: 18, md: 19, lg: 20 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: "18px",
    ...responsiveFontSizes({ xs: 16, sm: 17, md: 18, lg: 18 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: "17px",
    ...responsiveFontSizes({ xs: 15, sm: 16, md: 17, lg: 17 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.2,
    fontSize: "15px",
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 1.2,
    fontSize: "14px",
  },
  body1: {
    lineHeight: 1.2,
    fontSize: "16px",
  },
  body2: {
    lineHeight: 1.2,
    fontSize: "14px",
    color: "grey",
  },
  caption: {
    lineHeight: 1.2,
    fontSize: "12px",
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: "12px",
    textTransform: "uppercase",
  },
  button: {
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: "14px",
    textTransform: "capitalize",
  },
};

export default typography;
