import { Box, Container, Typography } from "@mui/material";
import { Play } from "../common/constants";
import React from "react";
import Hourglass from "../resources/images/StaticPageImg/Hourglass.png";
import SphericalCube from "../resources/images/StaticPageImg/SphericalCube.png";
import Mobius from "../resources/images/StaticPageImg/Mobius.png";
import MobiusStrip from "../resources/images/StaticPageImg/MobiusStrip.png";
import Pill from "../resources/images/StaticPageImg/Pill.png";
import Cube from "../resources/images/StaticPageImg/Cube.png";
import Diamond from "../resources/images/StaticPageImg/Diamond.png";
import Asterisk from "../resources/images/StaticPageImg/Asterisk.png";

const StaticPageDes = ({ title, des1, des2 }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#E4E4E38A",
          padding: "30px 30px 150px",
          borderRadius: "30px",
          mt: 10,
        }}
      >
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "24px", md: "36px" },
              textTransform: "uppercase",
              fontFamily: "Grandstander, sans-serif !important",
              color: "common.black",
              mb: 1,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              color: "common.black",
              fontFamily: Play,
              mb: 1,
            }}
          >
            {des1}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              color: "common.black",
              fontFamily: Play,
              mb: 1,
            }}
          >
            {des2}
          </Typography>
        </Box>
      </Box>
      <Container
        maxWidth="lg"
        sx={{
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        }}
      >
        <Box sx={{ position: "relative", top: "-140px" }}>
          <Box
            sx={{
              position: "absolute",
              left: "0",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              overflowX: "hidden",
            }}
            className="floating_images"
          >
            <Box
              sx={{
                minWidth: "120px",
                minHeight: "120px",
                maxWidth: "120px",
                maxHeight: "120px",
                mt: "30px",
              }}
            >
              <img
                src={Hourglass}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  animation: "floatUpDown 3s ease-in-out infinite",
                  animationDelay: "0.5s",
                }}
              />
            </Box>
            <Box
              sx={{
                minWidth: "130px",
                minHeight: "130px",
                maxWidth: "130px",
                maxHeight: "130px",
                mt: "-50px",
                ml: "-30px",
              }}
            >
              <img
                src={SphericalCube}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  animation: "floatUpDown 3s ease-in-out infinite",
                  animationDelay: "1s",
                }}
              />
            </Box>
            <Box
              sx={{
                minWidth: "80px",
                minHeight: "80px",
                maxWidth: "80px",
                maxHeight: "80px",
                mt: "40px",
                ml: "0px",
              }}
            >
              <img
                src={Mobius}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  animation: "floatUpDown 3s ease-in-out infinite",
                  animationDelay: "1.5s",
                }}
              />
            </Box>
            <Box
              sx={{
                minWidth: "160px",
                minHeight: "160px",
                maxWidth: "160px",
                maxHeight: "160px",
                mt: "-90px",
                mr: "-30px",
              }}
            >
              <img
                src={MobiusStrip}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  animation: "floatUpDown 3s ease-in-out infinite",
                  animationDelay: "2s",
                }}
              />
            </Box>
            <Box
              sx={{
                minWidth: "140px",
                minHeight: "140px",
                maxWidth: "140px",
                maxHeight: "140px",
              }}
            >
              <img
                src={Pill}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  animation: "floatUpDown 3s ease-in-out infinite",
                  animationDelay: "2.5s",
                }}
              />
            </Box>
            <Box
              sx={{
                minWidth: "180px",
                minHeight: "180px",
                maxWidth: "180px",
                maxHeight: "180px",
                mt: "-90px",
              }}
            >
              <img
                src={Diamond}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  animation: "floatUpDown 3s ease-in-out infinite",
                  animationDelay: "3s",
                }}
              />
            </Box>
            <Box
              sx={{
                minWidth: "180px",
                minHeight: "180px",
                maxWidth: "180px",
                maxHeight: "180px",
                mt: "60px",
              }}
            >
              <img
                src={Cube}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  animation: "floatUpDown 3s ease-in-out infinite",
                  animationDelay: "3.5s",
                }}
              />
            </Box>
            <Box
              sx={{
                minWidth: "180px",
                minHeight: "180px",
                maxWidth: "180px",
                maxHeight: "180px",
              }}
            >
              <img
                src={Asterisk}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  animation: "floatUpDown 3s ease-in-out infinite",
                  animationDelay: "4s",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default StaticPageDes;
