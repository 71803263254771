import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { Suspense } from "react";
import ScrollToTop from "./components/ScrollToTop";
import Loader from "./components/Loader";
import Routes from "./routes/Routes";
import { BrowserRouter } from "react-router-dom";
import { styled } from "@mui/material";
import { SnackbarProvider } from "notistack";
import ThemeProvider from "./theme";
import { HelmetProvider } from "react-helmet-async";
import { AuthContextProvider } from "./context/AuthContext";
import "./css/App.css";
// import AppLogout from "./context/AppAutoLogout";
import { FigAnalyticsContextProvider } from "./context/FigAnalyticsContext";

// alert notification
const StyledSnackbarProvider = styled(SnackbarProvider)`
  // &.notistack-MuiContent-success {
  //   background-color: #ffffff;
  //   color: #000000;
  // }
  ,
  &.notistack-MuiContent {
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
      0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

const App = () => {
  return (
    <AuthContextProvider>
      <FigAnalyticsContextProvider>
        <BrowserRouter>
          <HelmetProvider>
            <ThemeProvider>
              <ScrollToTop />
              {/* <IdleTimer /> */}
              <StyledSnackbarProvider
                hideIconVariant
                autoHideDuration={3000}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <Suspense fallback={<Loader />}>
                  <Routes />
                </Suspense>

                {/* <AppLogout>
                  <Suspense fallback={<Loader />}>
                    <Routes />
                  </Suspense>
                </AppLogout> */}
              </StyledSnackbarProvider>
            </ThemeProvider>
          </HelmetProvider>
        </BrowserRouter>
      </FigAnalyticsContextProvider>
    </AuthContextProvider>
  );
};

export default App;
