import React, { useEffect, useState } from "react";
import "../css/homePage.css";
import HeroSection from "../components/HeroSection";
import { Helmet } from "react-helmet-async";
import MarqueeAnimationSection from "../components/MarqueeAnimationSection";
import FigpassSection from "../components/FigpassSection";
import AboutSection from "../components/AboutSection";
import StickyPhoneSection from "../components/StickyPhoneSection";
import FeaturesSection from "../components/FeaturesSection";
import TestimonialSection from "../components/TestimonialSection";
import Footer from "../components/Footer";
import axios from "axios";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [totalGems, setTotalGems] = useState(null);
  const [figPlan, setFigPlan] = useState(null);
  const [gem, setGem] = useState(null);
  const [community, setCommunity] = useState(null);
  const [simulatorMedia, setSimulatorMedia] = useState(null);
  const [gamePlayMedia, setGamePlayMedia] = useState(null);
  const [feedbackData, setFeedbackData] = useState(null);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/web/landing-page-data`)
      .then((response) => {
        setLoading(false);
        setTotalGems(response?.data?.data?.distributionAvailableBalance);
        setFigPlan(response?.data?.data?.figPlan);
        setGem(response?.data?.data?.gem);
        setCommunity(response?.data?.data?.community);
        setSimulatorMedia(response?.data?.data?.simulatorMedia);
        setGamePlayMedia(response?.data?.data?.gamePlayMedia);
        setFeedbackData(response?.data?.data?.feedbacks);
        setData(response?.data?.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  console.log(data)

  return (
    <>
      <Helmet>
        <title>Home | Figrun</title>
      </Helmet>
      <HeroSection totalGems={totalGems} />
      <MarqueeAnimationSection />
      <FigpassSection figPlan={figPlan} gem={gem} />
      <AboutSection />
      <StickyPhoneSection simulatorMedia={simulatorMedia} gamePlayMedia={gamePlayMedia} />
      <FeaturesSection community={community} data={data} />
      <TestimonialSection feedbackData={feedbackData}/>
      <Footer />
    </>
  );
};

export default Home;
