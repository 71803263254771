import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container } from "@mui/material";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet-async";
import StaticPageDes from "../components/StaticPageDes";
import Footer from "../components/Footer";
import axios from "axios";

const Partnership = () => {
  const [loading, setLoading] = useState(true);
  const [aboutUsMedia, sesAboutUsMedia] = useState();

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/web/partnership/media`)
      .then((response) => {
        setLoading(false);
        sesAboutUsMedia(response?.data?.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Partnership | Figrun</title>
      </Helmet>
      <Box
        sx={{
          overflowX: "hidden",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box
          sx={{
            height: "80vh",
            minHeight: "700px",
            overflow: "scroll",
            padding: 2.5,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            id="hero"
            sx={{
              height: "100%",
              borderRadius: { xs: "15px", sm: "30px", md: "50px" },
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              position: "relative",
              backgroundColor: "#ece7e4",
            }}
          >
            {aboutUsMedia && aboutUsMedia.media ? (
              aboutUsMedia.mediaType === "video" ? (
                <video
                  src={aboutUsMedia.media}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "inherit",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    backgroundImage: `url(${aboutUsMedia.media})`,
                    height: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: "inherit",
                  }}
                />
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <Navbar />
          </Box>
        </Box>
        <Container maxWidth="lg">
          <StaticPageDes
            title={"PARTNERSHIP"}
            des1={
              "At Figrun, we believe that partnerships are key to expanding the boundaries of our unique exploration experience. We are actively seeking innovative partners who are ready to engage with a global audience and leverage the power of gamified exploration. Whether you are a retail business looking to drive foot traffic, a tourism company aiming to offer new engagement methods for travelers, or a brand that wants to connect with users in a more interactive way, Figrun offers custom partnership opportunities."
            }
            des2={
              "By aligning with Figrun, you gain access to our rapidly growing user base, unlock new promotional avenues, and foster deeper engagement through interactive discovery. Let’s create unforgettable experiences and rewards for users while boosting your brand presence in a unique and playful manner."
            }
          />
        </Container>
        <Footer />
      </Box>
    </>
  );
};

export default Partnership;
