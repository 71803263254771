import { Box, Chip, Divider, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { Play } from "../common/constants";

import FigFreeImage from '../resources/images/card-one-image.png'
import FigLetImage from '../resources/images/card-two-image.png'
import FigLiteImage from '../resources/images/card-three-image.png'
import FigFlowImage from '../resources/images/card-four-image.png'
import FigPeakImage from '../resources/images/card-five-image.png'

const FigpassCard = ({
  icon,
  name,
  price,
  description,
  gems,
  grainPerFig,
  grainsLevelUp,
}) => {

  const card = () => {
    if (name === "FIGFREE") {
      return {
        bgImage: FigFreeImage,
        textColor: "Primary.main",
      }
    }
    if (name === "FIGLET") {
      return {
        bgImage: FigLetImage,
        textColor: "#FF3D00",
      }
    }
    if (name === "FIGLITE") {
      return {
        bgImage: FigLiteImage,
        textColor: "#E55EFF",
      }
    }
    if (name === "FIGFLOW") {
      return {
        bgImage: FigFlowImage,
        textColor: "#00C800",
      }
    }
    if (name === "FIGPEAK") {
      return {
        bgImage: FigPeakImage,
        textColor: "#13194B",
      }
    }

    return {
      bgImage: "",
      color: "",
    }
  }
  
  const StyledChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: "#fff",
    fontWeight: 700,
    fontSize: "18px",
    paddingY: "5px !important",
    paddingX: "20px !important",
    height: "auto",
    borderRadius: "102px",
    "& .MuiChip-label": {
      fontFamily: Play,
      color: card().textColor
    },
  }));

  return (
    <Box
      sx={{
        backgroundImage: `url(${card().bgImage})`,
        backgroundColor: "primary.main",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: {xs: '20px', sm:"30px"},
        pb: "19px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: 'end',
        transition: "all 0.5s ease-in",
        "&:hover": {
          backgroundSize: "150%",
        },
      }}
    >
      <img className="figcard-icon" src={icon} alt="figplan icon" />
      <Stack direction={"row"} alignItems={"end"} gap={1}>
        <Typography
          sx={{
            fontSize: { xs: "50px", sm: "50px" },
            fontWeight: 900,
            lineHeight: { xs: "42px", sm: "40px" },
            letterSpacing: "-0.05em",
            textAlign: "left",
            color: "#fff",
            mt: "12px",
            ml: "18px",
            fontFamily: "Grandstander, sans-serif !important",
          }}
        >
          {name}
        </Typography>
        {price && (
          <Typography
            component={"span"}
            sx={{
              fontSize: { xs: "18px", sm: "24px" },
              fontWeight: 900,
              color: "#fff",
              fontFamily: "Grandstander, sans-serif !important",
              // display: 'inline-flex',
              // alignItems: 'end'
            }}
          >
            ${price}
          </Typography>
        )}
      </Stack>
      <Typography
        variant="h4"
        sx={{
          fontFamily: Play,
          fontSize: { xs: "16px", sm: "20px" },
          color: "#fff",
          mt: "12px",
          ml: "18px",
          mr: "24px",
          textTransform: "uppercase",
          lineHeight: "23.14px",
          whiteSpace: 'pre-line'
        }}
      >
        {description}
      </Typography>
      {name !== "GEMS" && <Box 
        // flex={1} alignContent={"end"}
      >
        <Divider
          sx={{
            borderColor: "#fff",
            mx: "18px",
            mt: "10.5px",
          }}
        />
        <Stack
          direction={"row"}
          mt={"13.5px"}
          mx={"16px"}
          flexWrap={"wrap"}
          gap={2}
        >
          <StyledChip
            label={`${gems} ${price ? 'GEMS' : "FREE GEMS"}`}
            variant="filled"
            sx={{
              lineHeight: { xs: "20px", sm: "30px" },
              fontSize: { xs: "14px", sm: "18px" },
              paddingY: "5px !important",
              paddingX: "20px !important",
            }}
          />
          <StyledChip
            label={`${grainPerFig} GRAIN / FIG`}
            variant="filled"
            sx={{
              lineHeight: { xs: "20px", sm: "30px" },
              fontSize: { xs: "14px", sm: "18px" },
              paddingY: "5px !important",
              paddingX: "20px !important",
            }}
          />
          <StyledChip
            label={`+${grainsLevelUp} GRAINS LVL UPS`}
            variant="filled"
            sx={{
              lineHeight: { xs: "20px", sm: "30px" },
              fontSize: { xs: "14px", sm: "18px" },
              paddingY: "5px !important",
              paddingX: "20px !important",
            }}
          />
        </Stack>
      </Box>}
    </Box>
  );
};

export default FigpassCard;
