import React, { useRef, useEffect, useState, Fragment } from "react";
import {
  Box,
  Skeleton,
  IconButton,
  useMediaQuery,
  Stack,
  Typography,
  useTheme,
  SpeedDialIcon,
  SpeedDial,
  SpeedDialAction,
} from "@mui/material";

import Typewriter from "typewriter-effect";
import { ReactComponent as Shape } from "../resources/icons/Shape_blue-donute-u.svg";
import { ReactComponent as DownloadOn } from "../resources/icons/download-on.svg";
import Navbar from "./Navbar";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { BoxIcon, Locate, Map, Satellite, X } from "lucide-react";
import axios from "axios";
import { useSnackbar } from "notistack";
import FigFree from "../resources/icons/FigFree.png";
import FigLet from "../resources/icons/FigLet.png";
import FigLite from "../resources/icons/FigLite.png";
import FigFlow from "../resources/icons/FigFlow.png";
import FigPeak from "../resources/icons/FigPeak.png";
import { convertToGeoJSON } from "../common/utils/CommonFunctions";
import { useSearchParams } from "react-router-dom";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_SECRET_KEY;

const HeroSection = ({ totalGems }) => {
  const theme = useTheme();
  const isDownToSM = useMediaQuery(theme.breakpoints.down("sm"));
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [selectedMap, setSelectedMap] = useState(
    searchParams.get("view") ? searchParams.get("view") : "Default"
  );
  const [fetchingCurrentPos, setFetchingCurrentPos] = useState(true);
  const [currentPos, setCurrentPos] = useState({
    lng: undefined,
    lat: undefined,
  });
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(14);
  const [isDeniedFetchingLocation, setIsDeniedFetchingLocation] =
    useState(false);
  const [figs, setFigs] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenSpeedDial = () => setOpenSpeedDial(true);

  const handleCloseSpeedDial = () => setOpenSpeedDial(false);

  const actions = [
    {
      icon: (
        <BoxIcon
          color={theme.palette.common.black}
          strokeWidth={1.75}
          size={22}
        />
      ),
      name: "3D",
    },
    {
      icon: (
        <Satellite
          color={theme.palette.common.black}
          strokeWidth={1.75}
          size={22}
        />
      ),
      name: "Satellite",
    },
    {
      icon: (
        <Map color={theme.palette.common.black} strokeWidth={1.75} size={22} />
      ),
      name: "Default",
    },
  ];

  useEffect(() => {
    if (map.current) {
      if (lat && lng && !isNaN(lat) && !isNaN(lng)) {
        map.current.setCenter([lng, lat]);
        map.current.setZoom(zoom);
      } else {
        console.error("Invalid coordinates: ", lat, lng);
      }
    }
  }, [lat, lng, zoom]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setLng(pos.coords.longitude.toFixed(4));
        setLat(pos.coords.latitude.toFixed(4));

        setCurrentPos({
          lat: pos.coords.latitude.toFixed(4),
          lng: pos.coords.longitude.toFixed(4),
        });
        map?.current?.setCenter([
          pos.coords.longitude.toFixed(4),
          pos.coords.latitude.toFixed(4),
        ]);
        setFetchingCurrentPos(false);
      },
      (error) => {
        console.log(error);
        setFetchingCurrentPos(false);
        setIsDeniedFetchingLocation(true);
      },
      { timeout: 10000 }
    );
  }, []);

  const handleLocateClick = () => {
    if (currentPos.lat && currentPos.lng) {
      setLat(currentPos.lat);
      setLng(currentPos.lng);
      setZoom(14);
      if (map.current) {
        map.current.setCenter([currentPos.lng, currentPos.lat]);
        map.current.setZoom(14);
      }
    } else {
      enqueueSnackbar(
        "Please enable location permissions to discover figs around you",
        {
          variant: "warning",
        }
      );
      console.error("Current position is not available");
    }
  };

  useEffect(() => {
    if (currentPos.lat && currentPos.lng && map.current) {
      const marker = document.createElement("div");

      const width = 20;
      const height = 20;
      marker.className = "marker";
      marker.style.backgroundColor = theme.palette.primary.main;
      marker.style.border = "3px solid";
      marker.style.borderColor = theme.palette.common.white;
      marker.style.boxShadow = `0px 0px 15px 1px ${theme.palette.primary.light}`;
      marker.style.width = `${width}px`;
      marker.style.height = `${height}px`;

      new mapboxgl.Marker(marker)
        .setLngLat([currentPos.lng, currentPos.lat])
        .addTo(map.current);
    }
  }, [currentPos, selectedMap, figs]);

  const getFigs = async () => {
    const config = {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    try {
      const resource = await axios.get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/web/figs?latitude=${lat}&longitude=${lng}&status=active&isWorldMap=${
          zoom < 4 ? true : false
        }`,
        config
      );

      setFigs(resource?.data?.data?.figs);
      return resource?.data?.data?.figs || [];
    } catch (error) {
      if (error?.response?.data?.message) {
        enqueueSnackbar(error?.response?.data?.message || "", {
          variant: "error",
        });
      }
      return [];
    }
  };

  useEffect(() => {
    map?.current?.setLayoutProperty("clusters", "visibility");
    map?.current?.setLayoutProperty("unclustered-point", "visibility");
    map?.current?.setLayoutProperty("unclustered-likes", "visibility");
    getFigs();
  }, [lat, lng]);

  const updateGeoJSONData = (featureId, newSize) => {
    const source = map.current.getSource("figs");
    const data = source?._data;

    const updatedFeatures = data?.features?.map((feature) => {
      if (feature.id === featureId) {
        feature.properties.iconSize = newSize;
      } else {
        feature.properties.iconSize = 0.4;
      }
      return feature;
    });
    data.features = updatedFeatures;

    map.current.getSource("figs")?.setData(data);
  };

  useEffect(() => {
    if (fetchingCurrentPos) return;

    try {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style:
          selectedMap === "Satellite"
            ? "mapbox://styles/mapbox/satellite-streets-v12"
            : "mapbox://styles/figrun/clyo7p37p03ns01r15qmp6vqm",
        showPointOfInterestLabels: false,
        center: [lng, lat],
        zoom: zoom,
        attributionControl: false,
        pitch: selectedMap === "3D" ? 35 : 0,
        bearing: -17.6,
        antialias: true,
        minZoom: 1,
      });
      map.current?.addControl(new mapboxgl.NavigationControl());
      map.current.on("moveend", () => {
        setLng(map.current.getCenter().lng.toFixed(4));
        setLat(map.current.getCenter().lat.toFixed(4));
        setZoom(map.current.getZoom().toFixed(2));
      });

      if (!map.current?.hasImage("figfree")) {
        map.current?.loadImage(FigFree, (error, image) => {
          if (error) throw error;
          map.current.addImage("figfree", image);
        });
      }
      if (!map.current?.hasImage("figlet")) {
        map.current?.loadImage(FigLet, (error, image) => {
          if (error) throw error;
          map.current.addImage("figlet", image);
        });
      }
      if (!map.current?.hasImage("figlite")) {
        map.current?.loadImage(FigLite, (error, image) => {
          if (error) throw error;
          map.current.addImage("figlite", image);
        });
      }
      if (!map.current?.hasImage("figflow")) {
        map.current?.loadImage(FigFlow, (error, image) => {
          if (error) throw error;
          map.current.addImage("figflow", image);
        });
      }
      if (!map.current?.hasImage("figpeak")) {
        map.current?.loadImage(FigPeak, (error, image) => {
          if (error) throw error;
          map.current.addImage("figpeak", image);
        });
      }

      map.current.loadImage(
        "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
        (error, image) => {
          if (error) throw error;
          map.current.addImage("my-image", image);
        }
      );

      map.current.on("load", async () => {
        const data = await getFigs();
        const geojsonData = convertToGeoJSON(data);

        const uniqueImages = {};

        geojsonData.features.forEach((item) => {
          if (
            item.properties.figImage &&
            !uniqueImages[item.properties.figImage]
          ) {
            uniqueImages[item.properties.figImage] = item.properties.figImage;
          }
        });

        const loadImages = Object.entries(uniqueImages).map(([name, url]) => {
          return new Promise((resolve, reject) => {
            if (!map.current?.hasImage(name)) {
              map.current?.loadImage(url, (error, image) => {
                if (error) {
                  console.log(error);
                  // reject(error);
                } else {
                  if (!map.current?.hasImage(name)) {
                    map.current.addImage(name, image);
                  }
                  resolve();
                }
              });
            } else {
              resolve();
            }
          });
        });

        Promise.all(loadImages).then(() => {
          if (!map.current.getSource("figs")) {
            map.current.addSource("figs", {
              type: "geojson",

              data: geojsonData,

              cluster: true,
              clusterMaxZoom: 14,
              clusterRadius: 50,
              clusterProperties: {
                figfreeCount: [
                  "+",
                  ["case", ["==", ["get", "figType"], "figfree"], 1, 0],
                ],
                figletCount: [
                  "+",
                  ["case", ["==", ["get", "figType"], "figlet"], 1, 0],
                ],
                figliteCount: [
                  "+",
                  ["case", ["==", ["get", "figType"], "figlite"], 1, 0],
                ],
                figflowCount: [
                  "+",
                  ["case", ["==", ["get", "figType"], "figflow"], 1, 0],
                ],
                figpeakCount: [
                  "+",
                  ["case", ["==", ["get", "figType"], "figpeak"], 1, 0],
                ],
              },
            });
          }

          map.current.addLayer({
            id: "clusters",
            type: "symbol",
            source: "figs",
            filter: ["has", "point_count"],
            layout: {
              "icon-image": [
                "case",
                [
                  ">=",
                  ["get", "figfreeCount"],
                  [
                    "max",
                    ["get", "figletCount"],
                    ["get", "figliteCount"],
                    ["get", "figflowCount"],
                    ["get", "figpeakCount"],
                  ],
                ],
                "figfree",
                [
                  ">=",
                  ["get", "figletCount"],
                  [
                    "max",
                    ["get", "figliteCount"],
                    ["get", "figflowCount"],
                    ["get", "figpeakCount"],
                  ],
                ],
                "figlet",
                [
                  ">=",
                  ["get", "figliteCount"],
                  ["max", ["get", "figflowCount"], ["get", "figpeakCount"]],
                ],
                "figlite",
                [">=", ["get", "figflowCount"], ["get", "figpeakCount"]],
                "figflow",
                "figpeak",
              ],

              "text-anchor": "left",
              "text-field": ["get", "point_count_abbreviated"],
              "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
              "text-size": 24,
              "text-offset": [2.25, 0.1],
              "icon-size": 0.55,

              "icon-allow-overlap": true,
              "text-allow-overlap": true,
            },
            paint: {
              "text-color": selectedMap === "Satellite" ? "#ffffff" : "#000000",
              "text-halo-width": 4.0,
              "text-halo-color":
                selectedMap !== "Satellite" ? "#ffffff" : "#000000",
            },
          });

          map.current.addLayer({
            id: "unclustered-point",
            type: "symbol",
            source: "figs",
            filter: ["!", ["has", "point_count"]],
            layout: {
              "icon-image": ["get", "figImage"],
              "icon-size": ["get", "iconSize"],
              "icon-allow-overlap": true,
              "text-allow-overlap": true,
            },
          });
          map.current.on("click", "clusters", (e) => {
            const features = map.current.queryRenderedFeatures(e.point, {
              layers: ["clusters"],
            });
            const clusterId = features[0].properties.cluster_id;
            map.current
              .getSource("figs")
              .getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;

                map.current.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom,
                });
              });
          });

          map.current.on("mouseenter", "clusters", () => {
            map.current.getCanvas().style.cursor = "pointer";
          });
          map.current.on("mouseleave", "clusters", () => {
            map.current.getCanvas().style.cursor = "";
          });
        });
      });

      map.current.on("click", (e) => {
        if (+map.current.getZoom().toFixed(2) >= 4) {
          const features = map.current.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });

          const unclusteredFeatures = map.current.queryRenderedFeatures(
            e.point,
            {
              layers: ["unclustered-point"],
            }
          );

          if (!unclusteredFeatures.length) {
            const source = map.current.getSource("figs");
            const data = source?._data;
            data?.features?.forEach((feature) => {
              feature.properties.iconSize = 0.4;
            });
            source?.setData(data);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [selectedMap, fetchingCurrentPos]);

  useEffect(() => {
    const geojsonData = convertToGeoJSON(figs);
    const uniqueImages = {};

    geojsonData.features.forEach((item) => {
      if (item.properties.figImage && !uniqueImages[item.properties.figImage]) {
        uniqueImages[item.properties.figImage] = item.properties.figImage;
      }
    });

    const loadImages = Object.entries(uniqueImages).map(([name, url]) => {
      return new Promise(async (resolve, reject) => {
        if (!map.current?.hasImage(name)) {
          map.current?.loadImage(url, (error, image) => {
            if (error) {
              console.log(error);
              // reject(error);
            } else {
              if (!map.current?.hasImage(name)) {
                map?.current?.addImage(name, image);
              }
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    });

    Promise.all(loadImages).then(() => {
      const source = map.current?.getSource("figs");

      if (source) {
        source?.setData(geojsonData);
      }
    });
  }, [figs]);

  return (
    <Box
      sx={{
        height: "80vh",
        minHeight: "700px",
        overflow: "scroll",
        padding: 2.5,
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "100%",
          borderRadius: 5,
          overflow: "hidden",
          backgroundColor: "#ece7e4",
        }}
      >
        {!fetchingCurrentPos && (
          <Fragment>
            {!isDeniedFetchingLocation && (
              <IconButton
                sx={{
                  position: "absolute",
                  bottom: 16,
                  right: 16,
                  zIndex: 1050,
                  width: 56,
                  height: 56,
                  bgcolor: "common.white",
                  boxShadow:
                    "0px 3px 5px -1px rgba(145, 158, 171, 0.2),0px 6px 10px 0px rgba(145, 158, 171, 0.14),0px 1px 18px 0px rgba(145, 158, 171, 0.12)",
                  ":hover": {
                    bgcolor: "common.white",
                  },
                }}
                onClick={handleLocateClick}
              >
                <Locate
                  color={theme.palette.common.black}
                  strokeWidth={1.75}
                  size={22}
                />
              </IconButton>
            )}
            <SpeedDial
              ariaLabel="SpeedDial controlled open example"
              sx={{
                position: "absolute",
                bottom: isDeniedFetchingLocation ? 80 : 80,
                right: 16,
                "& .MuiFab-root": {
                  bgcolor: "common.white",
                  ":hover": {
                    bgcolor: "common.white",
                  },
                },
              }}
              icon={
                <SpeedDialIcon
                  openIcon={<X color={theme.palette.common.black} size={22} />}
                  icon={actions.find((item) => item.name === selectedMap).icon}
                />
              }
              onClose={handleCloseSpeedDial}
              onOpen={handleOpenSpeedDial}
              open={openSpeedDial}
            >
              {actions.map((item) => (
                <SpeedDialAction
                  tooltipOpen
                  key={item.name}
                  icon={item.icon}
                  tooltipTitle={item.name}
                  onClick={() => {
                    setSelectedMap(item.name);
                    setSearchParams({ view: item.name });
                    handleCloseSpeedDial();
                  }}
                />
              ))}
            </SpeedDial>
          </Fragment>
        )}
        <Box
          ref={mapContainer}
          height="100%"
          visibility={fetchingCurrentPos ? "hidden" : "visible"}
          className="hero_section_map"
        />
        {fetchingCurrentPos ? (
          <Box sx={{ backgroundColor: " #ece7e4", height: "100%" }}></Box>
        ) : null}
        <Navbar />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            top: 100,
            right: 40,
            zIndex: 10,
          }}
        >
          <Stack direction={"row"}>
            <Box
              sx={{
                backgroundColor: "#fff",
                position: "relative",
                pl: "50px",
                pr: "13px",
                py: "5px",
                borderRadius: "20px",
              }}
            >
              <Shape
                style={{
                  position: "absolute",
                  left: -10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  width: "46px",
                  height: "46px",
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 800,
                  color: "#000",
                  display: "inline-flex",
                  alignItems: "center",
                  fontFamily: "Grandstander, sans-serif !important",
                }}
              >
                DISTRIBUTED AMOUNT &nbsp;
                <Typography
                  component={"span"}
                  variant="body1"
                  sx={{
                    fontWeight: 800,
                    color: theme.palette.primary.main,
                    fontFamily: "Grandstander, sans-serif !important",
                  }}
                >
                  {totalGems ? (
                    "$" + String(totalGems)
                  ) : (
                    <Skeleton variant="rectangular" width={100} height={16} />
                  )}
                </Typography>
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box
          sx={{
            maxWidth: { xs: "883px", xl: "1200px" },
            flex: 1,
            pt: { sm: "131px" },
            pb: { xs: "100px", sm: "202px" },
            alignContent: "end",
            px: { xs: 2, sm: 3, md: 5 },
            position: "absolute",
            top: 150,
            zIndex: 10,
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "34px", sm: "48px", xl: "58px" },
              fontWeight: 700,
              color: theme.palette.primary.main,
              letterSpacing: "-3px",
              textTransform: "capitalize",
              minHeight: {
                xs: "205px",
                sm: "205px",
                md: "145px",
                lg: "135px",
                xl: "175px",
              },
              fontFamily: "Grandstander, sans-serif !important",
            }}
          >
            <Typewriter
              options={{
                strings: [
                  "CONNECT WORLDS WITH EVERY TAP MAKE EVERY JOURNEY REWARDING",
                  "BRING THE THRILL OF DISCOVERY TO YOUR FINGERTIPS",
                  "WE TURN EVERY LOCATION INTO AN ADVENTURE",
                ],
                autoStart: true,
                loop: true,
                delay: 50,
                pauseFor: 2500,
              }}
            />
          </Typography>
          <DownloadOn
            width={isDownToSM ? "150px" : "215.02px"}
            style={{ marginTop: isDownToSM ? "10px" : "18px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
