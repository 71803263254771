import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import AboutPhone1 from "../resources/images/about_us_mobile_1.png";
import AboutPhone2 from "../resources/images/about_us_mobile_2.png";
import { Play } from "../common/constants";
import AboutCard from "./AboutCard";
import { About } from "../common/json/about";

const AboutSection = () => {
  return (
    <Container maxWidth={"xl"} sx={{ px: "0px !important" }}>
      <Box
        id="gameplay"
        data-section
        sx={{
          py: { xs: "50px" },
          px: { xs: "20px", sm: "50px", md: "65px" },
        }}
      >
        <Grid
          container
          spacing={{ xs: "61px" }}
          flexDirection={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                variant="h1"
                sx={{
                  color: "primary.main",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  lineHeight: { xs: "28.9px", sm: "39.9px" },
                  fontFamily: "Grandstander, sans-serif !important",
                }}
              >
                Dive into the stats that make Figrun a world of its own!
              </Typography>
              <Typography
                sx={{
                  fontFamily: Play,
                  color: "#9C9C9C",
                  fontSize: { xs: "24px" },
                  mt: "14px",
                  lineHeight: { xs: "24.3px" },
                }}
              >
                Figrun offers an expansive and engaging experience that grows
                every day. Explore, discover, and win with us as you navigate
                through a universe of possibilities.
              </Typography>

              <Grid container spacing={"12px"} mt={{ xs: "38px" }}>
                {About.map(({ subTitle, title, description }, i) => (
                  <Grid key={i} item xs={12} sm={6} md={12} lg={6}>
                    <AboutCard
                      subTitle={subTitle}
                      title={title}
                      description={description}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              marginTop: { lg: "auto" },
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  marginRight: "-100px",
                  marginTop: "-100px",
                }}
              >
                <img
                  loading="lazy"
                  src={AboutPhone1}
                  alt="Iphone 14 pro"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "auto",
                    position: "sticky",
                    top: 0,
                    animation:
                      "development_float__UCD_H 6s ease-in-out -3s infinite",
                  }}
                />
              </Box>
              <Box>
                <img
                  loading="lazy"
                  src={AboutPhone2}
                  alt="Iphone 14 pro"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "auto",
                    position: "sticky",
                    top: 0,
                    animation:
                      "development_float__UCD_H 6s ease-in-out infinite",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AboutSection;
