import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as Logo } from "../resources/icons/logo.svg";
import { navItems, Play } from "../common/constants";
import { Link, useLocation } from "react-router-dom";
// import { ReactComponent as Instagram } from "../resources/icons/instagram.svg";
import { ReactComponent as Instagram } from "../resources/icons/instagram2.svg";
import { ReactComponent as Tiktok } from "../resources/icons/tiktok2.svg";
import { ReactComponent as Youtube } from "../resources/icons/youtube2.svg";
import { ReactComponent as X } from "../resources/icons/x2.svg";
import { FooterBrandLink } from "../common/json/footer";
import { ReactComponent as ArrowTop } from "../resources/icons/arrow-left.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";

const Footer = () => {
  const [isLoading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const StyledLink = styled(Link)(({ theme }) => ({
    color: "#9C9C9C",
    textDecoration: "none",
    fontSize: "22px",
    cursor: "pointer",
    fontFamily: Play,
  }));

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const theme = useTheme();
  const isDownToSM = useMediaQuery(theme.breakpoints.down("sm"));

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);

      const config = {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/web/subscribe-email`,
          { email: values.email },
          config
        )
        .then((response) => {
          console.log(response?.message);
          enqueueSnackbar(response?.data?.message, {
            variant: "success",
          });
        })
        .catch((error) => {
          enqueueSnackbar(
            error?.response?.data?.message || "Something went wrong",
            {
              variant: "error",
            }
          );
        })
        .finally(() => {
          setLoading(false);
          formik.resetForm();
        });
    },
  });

  const FooterStyledLink = styled(HashLink)(({ theme }) => ({
    color: "#9C9C9C",
    textDecoration: "none",
    fontSize: "22px",
    cursor: "pointer",
    fontFamily: Play,
  }));

  return (
    <Box
      sx={{
        backgroundColor: [
          "/about-us",
          "/become-a-figmaster",
          "/partnership",
          "/investors",
          "/news",
          "/safety",
        ].includes(pathname)
          ? "transparent"
          : "#F7F7F7",
        pt: "80px",
        color: "#000",
      }}
    >
      <Box
        sx={{
          border: "1px solid #00000033",
          borderRadius: "42px 42px 0 0",
          backgroundColor: "#fff",
        }}
      >
        <Container maxWidth={"xl"} sx={{ px: "0px !important" }}>
          <Box
            sx={{
              // my: { xs: '50px' },
              py: { xs: "50px" },
              px: { xs: "20px", sm: "50px", md: "60px" },
            }}
          >
            <Typography
              sx={{
                color: "primary.main",
                fontSize: { xs: "32px", sm: "42px" },
                fontWeight: "900",
                letterSpacing: "-3px",
                fontFamily: "Grandstander, sans-serif !important",
              }}
            >
              FIGRüN
            </Typography>

            <Grid
              container
              columnSpacing={"60px"}
              rowSpacing={{ xs: "80px", lg: "0px" }}
              sx={{
                pt: "50px",
              }}
            >
              <Grid item xs={12} md={8} lg={5}>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "30px" },
                        fontWeight: 600,
                        textTransform: "uppercase",
                        lineHeight: "normal",
                        fontFamily: "Grandstander, sans-serif !important",
                      }}
                    >
                      SUBSCRIBE
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "self-start", mt: 2, gap:2 }}>
                      <Stack
                        direction={"row"}
                        gap={{ xs: 1, sm: 3 }}
                        maxWidth={"400px"}
                        width={"100%"}
                      >
                        <TextField
                          value={formik.values.email}
                          name="email"
                          onChange={(e) =>
                            formik.setFieldValue("email", e.target.value)
                          }
                          placeholder="Enter email address"
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          sx={{
                            fontFamily: "Play",
                            width: "100%",
                            maxWidth: "400px",
                            "& .MuiInputBase-root": {
                              fontSize: "18px",
                              fontFamily: "Play",
                              "& input::placeholder": {
                                color: "#000",
                              },
                            },
                          }}
                        />
                      </Stack>
                      <Button
                        variant="contained"
                        onClick={formik.handleSubmit}
                        disabled={isLoading}
                        sx={{
                          fontFamily: "Play",
                          fontSize: "19px",
                          fontWeight: 700,
                          padding: { xs: "10px 30px", sm: "19px 44.5px" },
                          lineHeight: "normal",
                          minHeight: "0px",
                          borderRadius: "12px",
                        }}
                      >
                        SEND
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={4} lg={2.33}>
                <Stack justifyContent={"end"}>
                  <Typography
                    sx={{
                      fontSize: { xs: "20px", sm: "24px" },
                      fontWeight: 700,
                      fontFamily: "Grandstander, sans-serif !important",
                    }}
                  >
                    FOLLOW US
                  </Typography>

                  <Stack gap={2} mt={3}>
                    <StyledLink
                      sx={{
                        fontSize: { xs: "16px", sm: "16px" },
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "14px",
                      }}
                      to={"https://www.instagram.com/figrun.app"}
                      target="_blank"
                    >
                      <Instagram
                        width={isDownToSM ? "20px" : "24px"}
                        style={{ flexShrink: 0 }}
                      />
                      Instagram
                    </StyledLink>
                    <StyledLink
                      sx={{
                        fontSize: { xs: "16px", sm: "16px" },
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "14px",
                      }}
                      to={"https://www.tiktok.com/@figrun"}
                      target="_blank"
                    >
                      <Tiktok
                        width={isDownToSM ? "20px" : "24px"}
                        style={{ flexShrink: 0 }}
                      />
                      TikTok
                    </StyledLink>
                    <StyledLink
                      sx={{
                        fontSize: { xs: "16px", sm: "16px" },
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "14px",
                      }}
                      to={"https://www.youtube.com/@Figrunapp"}
                      target="_blank"
                    >
                      <Youtube
                        width={isDownToSM ? "20px" : "24px"}
                        style={{ flexShrink: 0 }}
                      />
                      Youtube
                    </StyledLink>
                    <StyledLink
                      sx={{
                        fontSize: { xs: "16px", sm: "16px" },
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "14px",
                      }}
                      to={"https://x.com/figrun"}
                      target="_blank"
                    >
                      <X
                        width={isDownToSM ? "20px" : "24px"}
                        style={{ flexShrink: 0 }}
                      />
                      X.com
                    </StyledLink>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={6} lg={2.33}>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: { xs: "20px", sm: "24px" },
                      fontWeight: 700,
                      fontFamily: "Grandstander, sans-serif !important",
                    }}
                  >
                    MENU
                  </Typography>
                  <Stack gap={2} mt={3}>
                    {navItems.map((name, i) => (
                      <FooterStyledLink
                        key={i}
                        to={name.link}
                        smooth
                        sx={{
                          textTransform: "capitalize",
                          fontSize: { xs: "16px", sm: "16px" },
                        }}
                      >
                        {name.title.toLowerCase()}
                      </FooterStyledLink>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} lg={2.33}>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: { xs: "20px", sm: "24px" },
                      fontWeight: 700,
                      fontFamily: "Grandstander, sans-serif !important",
                    }}
                  >
                    BRAND
                  </Typography>
                  <Stack gap={2} mt={3}>
                    {FooterBrandLink.map(({ name, route }, i) => (
                      <StyledLink
                        key={i}
                        to={route}
                        sx={{
                          textTransform: "capitalize",
                          fontSize: { xs: "16px", sm: "16px" },
                        }}
                      >
                        {name}
                      </StyledLink>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>

            <Stack
              direction={{ xs: "row-reverse", sm: "row" }}
              flexWrap={"wrap"}
              sx={{
                fontSize: { xs: "20px", sm: "22px" },
                alignItems: "center",
                gap: 1,
                mt: "50px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "16px" },
                  fontFamily: "sans-serif!important",
                  fontWeight: 600,
                }}
              >
                ©&nbsp;
                <Box component={"span"}>
                  <Typography
                    component={"span"}
                    sx={{
                      color: "primary.main",
                      fontSize: { xs: "16px" },
                      fontWeight: "900",
                      letterSpacing: "-1px",
                      fontFamily: "Grandstander, sans-serif !important",
                    }}
                  >
                    FIGRüN
                  </Typography>
                </Box>
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: { xs: "16px" },
                    fontFamily: Play,
                  }}
                >
                  &nbsp;2024. All rights reserved&nbsp;
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: { xs: "16px" },
                    fontFamily: "sans-serif",
                    fontWeight: 700,
                    // display: {xs: 'none', sm: 'inline'}
                  }}
                >
                  {" "}
                  &#x2022;{" "}
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: { xs: "16px" },
                    fontFamily: Play,
                    fontWeight: 700,
                  }}
                >
                  <StyledLink
                    to={"https://api.figrun.com/privacy-policy"}
                    target="_blank"
                    sx={{
                      color: "#000",
                      textDecoration: "underline",
                      fontSize: { xs: "16px" },
                    }}
                  >
                    Privacy policy
                  </StyledLink>{" "}
                  &#x2022;{" "}
                  <StyledLink
                    to={"https://api.figrun.com/terms-and-conditions"}
                    target="_blank"
                    sx={{
                      color: "#000",
                      textDecoration: "underline",
                      fontSize: { xs: "16px" },
                    }}
                  >
                    Terms of use
                  </StyledLink>{" "}
                  &#x2022;{" "}
                  <StyledLink
                    target="_blank"
                    to={"/player-guidelines"}
                    sx={{
                      color: "#000",
                      textDecoration: "underline",
                      fontSize: { xs: "16px" },
                    }}
                  >
                    Player Guidelines
                  </StyledLink>{" "}
                  &#x2022;{" "}
                  <StyledLink
                    target="_blank"
                    to={"/event-code-of-conduct"}
                    sx={{
                      color: "#000",
                      textDecoration: "underline",
                      fontSize: { xs: "16px" },
                    }}
                  >
                    Event code of conduct
                  </StyledLink>
                </Typography>
              </Typography>

              <Button
                variant="text"
                disableRipple
                endIcon={
                  <ArrowTop
                    style={{
                      backgroundColor: "#006BFF",
                      borderRadius: "50%",
                      rotate: "90deg",
                      height: "30px",
                      width: "30px",
                      padding: "5px",
                    }}
                  />
                }
                sx={{
                  color: "#000",
                  fontSize: { xs: "20px", sm: "24px" },
                  fontWeight: 700,
                  p: 0,
                  marginLeft: "auto",
                  fontFamily: "Grandstander, sans-serif !important",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleScrollToTop}
              >
                BACK TO TOP
              </Button>
            </Stack>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
