import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Container } from "@mui/material";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet-async";
import StaticPageDes from "../components/StaticPageDes";
import Footer from "../components/Footer";
import axios from "axios";

const Investor = () => {
  const [loading, setLoading] = useState(true);
  const [aboutUsMedia, sesAboutUsMedia] = useState();

  const fetchData = async () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/web/investor/media`)
      .then((response) => {
        setLoading(false);
        sesAboutUsMedia(response?.data?.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Investors | Figrun</title>
      </Helmet>
      <Box
        sx={{
          overflowX: "hidden",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box
          sx={{
            height: "80vh",
            minHeight: "700px",
            overflow: "scroll",
            padding: 2.5,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box
            id="hero"
            sx={{
              height: "100%",
              borderRadius: { xs: "15px", sm: "30px", md: "50px" },
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              position: "relative",
              backgroundColor: "#ece7e4",
            }}
          >
            {aboutUsMedia && aboutUsMedia.media ? (
              aboutUsMedia.mediaType === "video" ? (
                <video
                  src={aboutUsMedia.media}
                  autoPlay
                  loop
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "inherit",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    backgroundImage: `url(${aboutUsMedia.media})`,
                    height: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: "inherit",
                  }}
                />
              )
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <Navbar />
          </Box>
        </Box>
        <Container maxWidth="lg">
          <StaticPageDes
            title={"INVESTORS"}
            des1={
              "Figrun is positioned at the intersection of gaming, exploration, and fintech, offering a unique blend of physical and digital experiences with real-world monetary rewards. As a rapidly growing platform, Figrun is opening its doors to investors who see the potential in transforming ordinary environments into discovery zones where users engage deeply and frequently. We have a clear roadmap for scaling, incorporating new features, and expanding globally. With monetization streams through in-app purchases, partnerships, and influencer-led campaigns, Figrun is primed for exponential growth. "
            }
            des2={
              "We are looking for investment partners who want to join us on this exciting journey, contributing to the development of new features and the expansion of our user base across diverse markets. Let's transform the way people play and win, together."
            }
          />
        </Container>
        <Footer />
      </Box>
    </>
  );
};

export default Investor;
