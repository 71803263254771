import React from "react";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ReactComponent as Quote } from "../resources/icons/quote.svg";
import { ReactComponent as ArrowLeft } from "../resources/icons/arrow-left.svg";
import { Play } from "../common/constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Testimonials } from "../common/json/testimonials";

const TestimonialSection = ({ feedbackData }) => {
  return (
    <Container maxWidth={"xl"} sx={{ px: "0px !important" }}>
      <Box
        sx={{
          // my: { xs: '50px' },
          py: { xs: "100px" },
          px: { xs: "20px", sm: "50px", md: "50px" },
          color: "#000",
        }}
      >
        <Grid container spacing={"52px"}>
          <Grid item xs={12} lg={4.1}>
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: "36px" },
                  fontWeight: 700,
                  lineHeight: "35.91px",
                  fontFamily: "Grandstander, sans-serif !important",
                  textTransform: "uppercase",
                }}
              >
                Unlock the World, One Adventure at a Time
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 300,
                  lineHeight: "23.94px",
                  mt: "40px",
                  textTransform: "uppercase",
                  letterSpacing: "1%",
                  fontFamily: "Grandstander, sans-serif !important",
                }}
              >
                Figrun isn’t just a game—it's a gateway to new experiences right
                where you are. Join us, and let every location reveal a story,
                every challenge a reward.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={7.9}>
            <Box>
              <Stack
                direction={{ xs: "column", md: "row" }}
                gap={"50px"}
                position={"relative"}
              >
                <Quote
                  style={{
                    flexShrink: 0,
                  }}
                />
                {feedbackData && feedbackData.length > 0 ? (
                  <>
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={84}
                      loop={true}
                      modules={[Navigation]}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                        },
                        450: {
                          slidesPerView: 1,
                        },
                        700: {
                          slidesPerView: 1,
                        },
                        1000: {
                          slidesPerView: 1,
                        },
                      }}
                      className="mySwiper"
                    >
                      {feedbackData && feedbackData.length > 0 ? (
                        feedbackData.map((data, i) => (
                          <SwiperSlide key={i}>
                            <Box>
                              <Typography
                                variant="h1"
                                sx={{
                                  fontWeight: 600,
                                  letterSpacing: "1%",
                                  mt: "10px",
                                  textTransform: "uppercase",
                                  color: "primary.main",
                                  fontFamily:
                                    "Grandstander, sans-serif !important",
                                    overflowWrap: "break-word",
                                  fontSize: {
                                    xs: "28px",
                                    sm: "40px",
                                    md: "50px",
                                  },
                                }}
                              >
                                {data?.message}
                              </Typography>
                              <Divider
                                sx={{
                                  mt: "11px",
                                  borderBottom: "2px solid #0000001F",
                                }}
                              />
                              <Stack
                                direction={{ xs: "column", sm: "row" }}
                                mt={"50px"}
                                ml={"10px"}
                                justifyContent={"space-between"}
                                gap={5}
                              >
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  gap={"30px"}
                                >
                                  <Avatar
                                    alt={"Image"}
                                    src={data?.user?.profilePic}
                                    sx={{
                                      width: 75,
                                      height: 75,
                                      border: "2.63px solid #006BFF",
                                    }}
                                  />
                                  <Stack
                                    alignItems={"start"}
                                    justifyContent={"center"}
                                  >
                                    <Typography
                                      sx={{
                                        textTransform: "uppercase",
                                        fontSize: "26px",
                                        fontWeight: 800,
                                      }}
                                    >
                                      {data?.user?.firstName +
                                        " " +
                                        data?.user?.lastName}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontFamily: "Play",
                                        fontSize: "20px",
                                      }}
                                    >
                                      {/* {position} */}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Box>
                          </SwiperSlide>
                        ))
                      ) : (
                        <Typography
                          variant="h6"
                          sx={{
                            color: "black",
                          }}
                        >
                          No feedback available
                        </Typography>
                      )}
                    </Swiper>
                    <Box
                      className="testimonial_swiper_slide"
                      sx={{
                        width: "100%",
                        // height: "100%",
                        position: "absolute",
                        zIndex: 100,
                        bottom: { xs: "80px", sm: "40px" },
                      }}
                    >
                      <IconButton
                        disableRipple
                        className="swiper-button-prev"
                        sx={{
                          left: "inherit",
                          right: "70px",
                          backgroundColor: "primary.main",
                          ":hover": {
                            backgroundColor: "primary.main",
                          },
                        }}
                      >
                        <ArrowLeft />
                      </IconButton>
                      <IconButton
                        disableRipple
                        className="swiper-button-next"
                        sx={{
                          backgroundColor: "primary.main",
                          ":hover": {
                            backgroundColor: "primary.main",
                          },
                        }}
                      >
                        <ArrowLeft style={{ rotate: "180deg" }} />
                      </IconButton>
                    </Box>
                  </>
                ) : (
                  <Typography
                    variant="h6"
                    sx={{
                      color: "black",
                    }}
                  >
                    No feedback available
                  </Typography>
                )}
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default TestimonialSection;
