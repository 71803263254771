import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import AWS from "aws-sdk";
import axios from "axios";
import figFlow from "../../resources/icons/FigFlow.png";
import figFree from "../../resources/icons/FigFree.png";
import figPeak from "../../resources/icons/FigPeak.png";
import figLet from "../../resources/icons/FigLet.png";
import figLite from "../../resources/icons/FigLite.png";

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const numDifferentiation = (val) => {
  if (val === undefined || val === null) return null;
  if (val >= 10000000) val = parseFloat((val / 10000000).toFixed(2)) + " Cr";
  else if (val >= 100000) val = parseFloat((val / 100000).toFixed(2)) + " Lac";
  else if (val >= 1000) val = parseFloat((val / 1000).toFixed(2)) + " K";
  return val < 10 ? "0" + val.toString() : val.toString();
};

export const toDMS = (degree, lng) => {
  const absolute = Math.abs(degree);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
  const direction =
    degree >= 0 ? (degree === lng ? "E" : "N") : degree === lng ? "W" : "S";

  return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const CompressImage = async (file, isResize) => {
  const options = {
    maxSizeMB: 1,
    useWebWorker: true,
    ...(isResize ? { maxWidthOrHeight: 154 } : {}),
  };

  const compressedFile = await imageCompression(file, options);
  return compressedFile;
};

export const HeicToJpeg = async (file) => {
  const jpegFile = await heic2any({
    blob: file,
    toType: "image/jpeg",
    quality: 1,
  });

  return jpegFile;
};

// export const convertToGeoJSON = (dataArray) => {
//   const features = dataArray.map((item) => ({
//     type: "Feature",
//     geometry: {
//       type: "Point",
//       coordinates: [item.longitude, item.latitude],
//     },
//     properties: {
//       id: item.id,
//       name: item.name,
//       gems: item.gems,
//       grain: item.grain,
//       hintImage: item.hintImage,
//       locationImage: item.locationImage,
//       qrCode: item.qrCode,
//       qrCodeImage: item.qrCodeImage,
//       uniqueId: item.uniqueId,
//       city: item.city,
//       country: item.country,
//       createdAt: item.createdAt,
//       figType: item.figType,
//     },
//   }));

//   return {
//     type: "FeatureCollection",
//     features: features,
//   };
// };

export const figImages = {
  figfree: figFree,
  figlet: figLet,
  figlite: figLite,
  figpeak: figPeak,
  figflow: figFlow,
};

export const convertToGeoJSON = (data) => {
  const geoJSON = {
    type: "FeatureCollection",
    features: [],
  };

  data?.forEach((item) => {
    if (item?.figLocation && item?.figLocation?.length > 0) {
      item?.figLocation?.forEach((location) => {
        const feature = {
          id: location?.id,
          type: "Feature",
          properties: {
            id: item?.id,
            uniqueId: item?.uniqueId,
            name: item?.name,
            qrCode: item?.qrCode,
            minimumUnlocksForReward: item?.minimumUnlocksForReward,
            figImage: item?.figImage
              ? item?.figImage
              : figImages[item?.figType],
            qrCodeImage: item?.qrCodeImage,
            figType: item?.figType,
            figUnlockType: item?.figUnlockType,
            isVirtuallyUnblocked: item?.isVirtuallyUnblocked,
            gems: item?.gems,
            grains: item?.grains,
            status: item?.status,
            createdAt: item?.createdAt,
            locationId: location?.id,
            locationMedia: location?.locationMedia,
            locationMediaType: location?.locationMediaType,
            thumbnail: location?.thumbnail,
            hintMedia: location?.hintMedia,
            iconSize: 0.4,
            userId: item?.admin?.id,
          },
          geometry: {
            type: "Point",
            coordinates: [location?.longitude, location?.latitude],
          },
        };
        geoJSON.features.push(feature);
      });
    }
  });

  return geoJSON;
};
export const convertToGeoJSONLikes = (data) => {
  const geoJSON = {
    type: "FeatureCollection",
    features: data.map((data) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [data.longitude, data.latitude],
      },
      properties: {
        id: data.id,
      },
    })),
  };

  return geoJSON;
};

let instance;

export const S3Upload = (file, token, identityId, isNotFig = "") => {
  return new Promise((resolve, reject) => {
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        IdentityId: identityId,
        Logins: {
          "cognito-identity.amazonaws.com": token,
        },
      }),
    });

    const S3Client = new AWS.S3();

    let shortName = "";
    if (file?.type?.includes("image")) {
      shortName = "IMG";
    } else if (file?.type?.includes("video")) {
      shortName = "VID";
    } else {
      shortName = "FIL";
    }

    const date = new Date();
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: `${process.env.REACT_APP_S3_MODE}/${
        isNotFig ? isNotFig : "figs"
      }/${shortName}_${date.getTime()}.${file?.name?.split(".")?.pop()}`,
      Body: file,
      ContentType: file.type,
    };

    S3Client.upload(params, function (err, data) {
      if (err) {
        console.error("Error uploading file:", err);
        reject({ success: false, error: err });
      } else {
        if (!isNotFig) {
          saveFilePath(data?.Location);
        }
        resolve({ success: true, ...data, name: file.name });
      }
    });
  });
};

const saveFilePath = (path) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
    };
    if (path) {
      axios.post(
        `${process.env.REACT_APP_API_URL}/figs/file-url/store`,
        { path },
        config
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const S3Abort = () => {
  if (instance) {
    try {
      instance.abort();
    } catch (error) {
      console.log(error);
    }
  }
};

export const getS3Token = async () => {
  const config = {
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/aws/token`,
      config
    );

    if (response?.data) {
      return {
        IdentityId: response?.data?.data?.identityId,
        Token: response?.data?.data?.token,
      };
    }
  } catch (error) {
    console.error(error);
    return;
  }
};

export const isUploading = () => {
  return instance;
};

export const getVideoDuration = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });

export const generateFigSKUNumber = (id) => {
  return "FR" + String(id).padStart(6, "0");
};

export const S3Delete = (path, token, identityId) => {
  return new Promise((resolve, reject) => {
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        IdentityId: identityId,
        Logins: {
          "cognito-identity.amazonaws.com": token,
        },
      }),
    });

    const S3Client = new AWS.S3();

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: `${
        process.env.REACT_APP_S3_MODE +
        path?.split(process.env.REACT_APP_S3_MODE)[1]
      }`,
    };

    S3Client.deleteObject(params, function (err, data) {
      if (err) {
        console.error("Error deleting file:", err);
        reject(err);
      }
      resolve();
    });
  });
};

export const S3CopyFile = async (sourceFile, identityId, token, type) => {
  return new Promise(async (resolve, reject) => {
    AWS.config.update({
      region: process.env.REACT_APP_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        IdentityId: identityId,
        Logins: {
          "cognito-identity.amazonaws.com": token,
        },
      }),
    });

    const s3 = new AWS.S3();

    const date = new Date();
    let shortName = "";
    if (type?.includes("image")) {
      shortName = "IMG";
    } else if (type?.includes("video")) {
      shortName = "VID";
    } else {
      shortName = "FIL";
    }

    const destKey = `${
      process.env.REACT_APP_S3_MODE
    }/${"figs"}/${shortName}_${date.getTime()}.${sourceFile
      ?.split(".")
      ?.pop()}`;

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      CopySource: `/${process.env.REACT_APP_BUCKET_NAME}/${process.env.REACT_APP_S3_MODE}/${sourceFile}`,
      Key: destKey,
    };

    console.log({ params });

    const result = await s3.copyObject(params).promise();

    const objectUrl = `https://${process.env.REACT_APP_BUCKET_NAME}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/${destKey}`;

    resolve({
      success: true,
      ...result,
      name: result?.name,
      Location: objectUrl,
    });
  });
};

export const isExistingStateFile = (filePath) => {
  return (
    filePath &&
    typeof filePath === "string" &&
    filePath.includes(`${process.env.REACT_APP_S3_MODE}/figs`)
  );
};
